// libraries
import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
// store
import { RootState, useAppSelector } from '../../../store/root'
// components
import PagePreloader from '../../shared/PagePreloader'
import { NOT_FOUND, ACCESS_DENIED, DASHBOARD, PROFILE } from '../../../constants/routes'
import findRoute from '../../../utils/findRoute'

export type TRoute = {
  path: string
  permissions: string[]
  // tslint:disable-next-line:ban-types
  data: Function
  // tslint:disable-next-line:ban-types
  rules: Function
  component: any
  _hasPermission?: boolean
  _hasData?: boolean
  _hasRules?: boolean
  _data?: any
  _rules?: any
}

type TAttributes = {
  routes: TRoute[]
}

const checkErrorPages = (routes: TRoute[], pathname: string) => (): React.ReactElement => {
  const route = routes.find((route: TRoute) => findRoute(route.path, pathname))

  if(!!route && (!route?._hasPermission || !route?._hasRules)) {
    return <Redirect to={{ pathname: ACCESS_DENIED, state: { from: window.location.pathname } }} />
  }

  return <Redirect to={{ pathname: NOT_FOUND, state: { from: window.location.pathname } }}/>
}

const PermissionManager = ({ routes }: TAttributes) => {
  const { pathname } = useLocation()
  const { personalNumber, state, loading, eServices, workspace } = useAppSelector((state: RootState) => state.user)
  const [ newRoutes, setNewRoutes ] = useState([] as any)

  useEffect(() => {
    if (!loading) {
      const promises = routes.map(calculate) as any

      (async (promises: any) => {
        const routes = []
        for await (const route of promises) {
          routes.push(route)
        }
        setNewRoutes(routes)
      })(promises)
    }
  }, [ loading, state, eServices, workspace ])

  const recalculate = () => {
    try {
      if (personalNumber) {
        const promises = routes.map(calculate) as any

        (async (promises: any) => {
          const routes = []
          for await (const route of promises) {
            routes.push(route)
          }
          setNewRoutes(routes)
        })(promises)
        return Promise.resolve(true)
      }
      return Promise.resolve(false)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async function calculate(route: TRoute) {
    if (route.permissions.length && !!state) {
      route._hasPermission = route.permissions.some((permission) => state === (permission as never))
    } else if (route.permissions.length === 0) {
      route._hasPermission = true
    } else {
      route._hasPermission = false
    }

    if (route._hasPermission) {
      const received = await route.data()
      if (received) {
        route._hasData = true
        route._data = received
      }
      const resolved = route.rules(received)
      if (resolved) {
        route._hasRules = true
        route._rules = resolved
      }
    }

    return route
  }

  const checkPermission = (route: TRoute): boolean => {
    return !!route._hasData && !!route._hasRules && !!route._hasPermission
  }

  return (
    !loading && newRoutes.length
      ?
      <Switch>
        {
          newRoutes && newRoutes.map((route: TRoute) => (
            checkPermission(route) &&
            <Route exact key={route.path} path={route.path} render={(routeProps) => (
              <route.component {...route._data} {...route._rules} {...routeProps} recalculate={recalculate}/>
            )}/>
          ))
        }
        <Route exact path={DASHBOARD} render={() => <Redirect to={PROFILE}/>}/>
        <Route render={checkErrorPages(newRoutes, pathname)}/>
      </Switch>
      : <PagePreloader/>
  )
}

export default PermissionManager
