import getEnvVar from '../utils/getEnvVar'
import { FeatureNames, isFeatureAvailable } from '../utils/features'

export const DASHBOARD = '/dashboard'
export const COMPANY_PROFILE = `/${isFeatureAvailable(FeatureNames.newProfile) 
  ? 'profile': 'dashboard'}/company-profile/edit`
export const WORKSPACES = '/workspaces'
export const E_SERVICES = '/e-services'
export const E_SERVICES_CORRECT_OCCUPATION = '/e-services/correct-occupation'
export const E_SERVICES_CORRECT_OCCUPATION_REQUEST = '/e-services/correct-occupation/request'
export const EXPATS = '/expats'
export const INVOICES = '/invoices'
export const E_SERVICES_POINTS = '/e-services/points'
export const E_SERVICES_BUY_POINTS = '/e-services/points/buy'
export const ERROR_SESSION = '/error-session'
export const NOT_FOUND = '/not-found'
export const ACCESS_DENIED = '/access-denied'
export const E_SERVICES_SAUDI_CERTIFICATE = '/e-services/saudi-certificate'
export const NOTIFICATIONS = '/notifications'
export const MAIN_LOADER_PAGE = '/'
export const E_SERVICES_WAGE_PROTECTION = '/e-services/wage-protection'
export const CHECK_WORKSPACES = '/check-workspaces'
export const E_SERVICES_WORKER_DATA = '/e-services/worker-data'
export const E_SERVICES_WORKER_DATA_SYNC = '/e-services/worker-data/sync'
export const E_SERVICES_DORMITORY_LICENSE_REQUESTS = '/e-services/dormitory-license'
export const E_SERVICES_DORMITORY_LICENSE_CREATE = '/e-services/dormitory-license/create'
export const E_SERVICES_DORMITORY_LICENSE_DETAILS = '/e-services/dormitory-license/details'
export const E_SERVICES_DORMITORY_LICENSE_EDIT = '/e-services/dormitory-license/edit'

// Musaned routes
// admin license requests
export const ADMIN_LICENSE_REQUESTS = `${getEnvVar('REACT_APP_MUSANED_URL')}/e-services/admin-license/requests`
// admin licenses
export const ADMIN_LICENSE_LIST = `${getEnvVar('REACT_APP_MUSANED_URL')}/e-services/admin-license/list`
// admin withdrawals
export const ADMIN_WITHDRAWAL_LIST = `${getEnvVar('REACT_APP_MUSANED_URL')}/e-services/admin-license/withdrawalList`
// admin packages
export const ADMIN_PACKAGES = `${getEnvVar('REACT_APP_MUSANED_URL')}/e-services/admin-packages/`
// establishments
export const ESTABLISHMENTS = `${getEnvVar('REACT_APP_MUSANED_URL')}/e-services/establishments`
// package price management
export const PACKAGE_PRICE = `${getEnvVar('REACT_APP_MUSANED_URL')}/e-services/package-price`

// spa's urls
export const LOOKUP_SPA = getEnvVar('REACT_APP_LOOKUP_SPA')
export const STATE_MANAGEMENT_SPA = getEnvVar('REACT_APP_STATE_MANAGEMENT_SPA')
export const USER_MANAGEMENT_SPA = getEnvVar('REACT_APP_USER_MANAGEMENT_SPA')
export const ADMIN_MANAGEMENT_SPA = getEnvVar('REACT_APP_ADMIN_SPA')
export const LOCALIZATION_SPA = getEnvVar('REACT_APP_LOCALIZATION_SPA')
export const OPERATION_SPA = getEnvVar('REACT_APP_OPERATION_SPA')
export const LANDING_SPA = getEnvVar('REACT_APP_LANDING_SPA')
export const PRIVACY_POLICY = `${getEnvVar('REACT_APP_PRIVACY_POLICY')}`
export const TERMS_CONDITIONS = `${getEnvVar('REACT_APP_TERMS_CONDITIONS')}`

// profiles
export const PROFILE = '/profile'
export const LABORER_PROFILE = getEnvVar('REACT_APP_LABORER_USER_PROFILE')
export const QIWA_PROFILE = getEnvVar('REACT_APP_QIWA_USER_PROFILE')

// mhrsd admin
export const MHRSD_DASHBOARD = `${getEnvVar('REACT_APP_ADMIN_SPA')}/mhrsd-dashboard`
export const MHRSD_RENEWAL_REQUEST = `${getEnvVar('REACT_APP_LICENSE_V2_SPA')}/e-services/admin-license/mhrsd/dashboard`
export const MHRSD_LICENCES = `${getEnvVar('REACT_APP_LICENSE_V2_SPA')}/e-services/admin-license/mhrsd/licenses`
export const MHRSD_WITHDRAWALS = `${getEnvVar('REACT_APP_LICENSE_V2_SPA')}/e-services/admin-license/withdrawalList`
export const MHRSD_MIGRATIONS = `${getEnvVar('REACT_APP_LICENSE_V2_SPA')}/migrations`
export const MHRSD_SUPER_ADMIN = `${getEnvVar('REACT_APP_LICENSE_V2_SPA')}/super-mhrsd-admin`
// Dormitory admin
export const DORMITORY_LICENSE = `${getEnvVar('REACT_APP_ADMIN_SPA')}/dormitory-license`
// L0 Admin
export const L0_DASHBOARD = `${getEnvVar('REACT_APP_LICENSE_V2_SPA')}/e-services/admin-license/labour-office/dashboard`
