import { createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'

type UtilsState = {
  traceId: string
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<UtilsState>({
  traceId: ''
})

const utils = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    setTraceId(state, action: PayloadAction<string>) {
      state.traceId = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        (action) => action.type.endsWith('rejected'),
        (state, action) => {
          state.traceId = action?.payload?.trace_id
        }
      )
  }
})

export const reducer: Reducer<typeof initialState> = utils.reducer;
export const { setTraceId } = utils.actions;
