import React from 'react'
import { Preloader } from '@takamol/unified-components'
import { Box } from '@material-ui/core'

const style: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9999,
  background: '#8590B3'
}

const PagePreloader = ({ opacity = 1 }: { opacity?: number }) => {
  return (
    <Box style={{ opacity, ...style }} data-testid='page-preloader'>
      <Preloader/>
    </Box>
  );
};

export default PagePreloader;
