import i18n from 'i18next'
import getEnvVar from './getEnvVar'
import { FeatureNames, isFeatureAvailable } from './features'

const getHorizontal = () => {
  return !isFeatureAvailable(FeatureNames.zendeskSupport)
    ? i18n.language === 'en' ? 'right' : 'left'
    : i18n.language === 'en' ? 'left' : 'right'
}
export const zendeskOptions = () => {
  try {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        color: {
          theme: '#0d91b8',
          launcherText: '#fff'
        },
        offset: {
          vertical: '40px'
        },
        position: {
          horizontal: getHorizontal()
        },
      }
    })
    window.zE('webWidget', 'setLocale', i18n.language === 'en' ? 'en-uk' : 'ar',)
    window.zE('webWidget', 'hide');
  }
  catch (e) {} // eslint-disable-line no-empty
}

export const zendeskForeignOptions = () => {
  try {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        offset: {
          vertical: '73px',
          mobile: {
            vertical: window
              .matchMedia(`(max-width: ${isFeatureAvailable(FeatureNames.commonLayout) ? 768 : 767}px)`)
              .matches ? `${isFeatureAvailable(FeatureNames.commonLayout) ? 262 : 292}px` : '73px'
          }
        },
        position: {
          horizontal: getHorizontal()
        },
      }
    })
  }
  catch (e) {} // eslint-disable-line no-empty
}

export const zendeskInit = () => {
  const url = `https://static.zdassets.com/ekr/snippet.js?key=${getEnvVar('REACT_APP_ZENDESK_KEY')}`
  const zendeskScript = document.createElement('script')
  zendeskScript.id = 'ze-snippet'
  document.body.appendChild(zendeskScript)
  if(isFeatureAvailable(FeatureNames.zendeskSupport)){
    zendeskScript.onload = () => zendeskOptions()
  } else {
    zendeskScript.onload = () => zendeskForeignOptions()
  }
  zendeskScript.src = url
}

export const changeZendeskLang = () => {
  try {
    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        position: {
          horizontal: getHorizontal()
        }
      })
      if(isFeatureAvailable(FeatureNames.zendeskSupport)) {
        window.zE('webWidget', 'setLocale', i18n.language === 'en' ? 'en-uk' : 'ar')
      }
    }
  }
  catch (e) {} // eslint-disable-line no-empty
}

export const handleZendesk = () => {
  try {
    window.zE.activate({ hideOnClose: true })
  }
  catch (e) {} // eslint-disable-line no-empty
}

export const setTraceId = (traceId: string) => {
  try {
    window.zE('webWidget', 'updateSettings', {
      contactForm: {
        fields: [
          { id: getEnvVar('REACT_APP_ZENDESK_TRACE_FIELD_ID'), prefill: { '*': `${traceId}` } }
        ]
      }
    })
  }
  catch (e) {} // eslint-disable-line no-empty
}

