import { Serializer, Deserializer } from 'jsonapi-serializer'

export const serializer = (type: string, data: any | object, options: any = { pluralizeType: false }) => {
  const attributes = Array.isArray(data) ? Object.keys(data[0]) : Object.keys(data)
  const opts = {
    attributes,
    ...options
  }
  return new Serializer(type, opts).serialize(data)
}

type TDeserialized = {
  data: any
  meta?: any
}

export const deserializer = (response: any, options: any = { keyForAttribute: 'camelCase' }): Promise<any> => {
  return new Deserializer(options).deserialize(response)
    .then((newData: any) => {
      const data: TDeserialized = {
        data: newData
      }
      if (response.meta) {
        data.meta = response.meta
      }
      return data
    })
}
