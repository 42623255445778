import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../store/root'
import { showModal } from '../store/modalManager'
import { useTranslation } from 'react-i18next'

export default () => {
  const { contextFailed } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleTryAgain = () => {
    const historyFrom = window.history?.state?.state?.from

    if(historyFrom) {
      return window.location.href = historyFrom
    }

    return window.location.reload()
  }

  useEffect(() => {
    if(contextFailed) {
      dispatch(showModal({
        variant: 'error',
        title: t('common.errorMessage'),
        text: t('common.somethingWentWrong'),
        onConfirm: handleTryAgain,
        onClose: handleTryAgain,
        overLapped: false,
      }))
    }
  }, [ contextFailed ])
}