import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_POINTS } from '../constants/api'
import { ErrorType, FetchResponse, SerializedError } from '../@types'

const stateAdapter = createEntityAdapter()

type PointsState = {
  loading: boolean
  error: SerializedError
  points: number
}

export const initialState = stateAdapter.getInitialState<PointsState>({
  error: {},
  loading: true,
  points: 0,
})

type FetchPointsResponse = {
  id: string
  value: number
}

export const fetchPoints = createAsyncThunk<FetchResponse<FetchPointsResponse>,
  void,
  ErrorType>(
    'points/fetchPoints',
    (_, { rejectWithValue }) => {
      return api('get', GET_POINTS, {}, rejectWithValue)
    }
  )

const points = createSlice({
  name: 'points',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPoints.fulfilled, (state, action) => {
        state.points = action.payload.data.value
        state.error = initialState.error
        state.loading = false
      })
      .addCase(fetchPoints.rejected, (state, action) => {
        state.loading = false
        state.error = { ...action.payload?.errors[0] }
      })
  }
});

export const reducer: Reducer<typeof initialState> = points.reducer;
export const { setLoading } = points.actions;
