import i18n from '../localization/i18n'
import { SerializedError } from '../@types'

export default (error: SerializedError | undefined): string => {
  if(error?.value) return i18n.language === 'en' ? error.value.en : error.value.ar

  return i18n.t(
    error?.title
      ? `errors.${error.title}`
      : 'common.somethingWentWrong'
  )
}
