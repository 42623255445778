import { PaletteType } from '@material-ui/core'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit'
import { serializer } from '@takamol/unified-components'
import api from '../services/axios'
import { SEND_LANG, SEND_THEME, CONTEXT } from '../constants/api'
import { ErrorType, FetchResponse, SerializedError } from '../@types'
import { REHYDRATE } from 'redux-persist/es/constants'
import { OmitedRehydrateAction } from './root'

export type Lang = 'ar' | 'en'

export type UserState = {
  lang: Lang
  theme: PaletteType
  error: null | SerializedError
  loading: boolean
  userName: string | null
  personalNumber: string | null
  img: string
  account: string
  state: string
  eServices: string[]
  workspace: string | null
  workspaceUrl: string | null
  establishmentNumber: string | null
  mobileIsOpen: boolean
  phoneNumber?: string
  notificationEmail?: string
  contextFailed: boolean
}

const stateAdapter = createEntityAdapter()

export const sendLang = createAsyncThunk<FetchResponse<Lang>, string, ErrorType>(
  'user/sendLang',
  async (locale, { dispatch, rejectWithValue }) => {
    dispatch(setLang(locale as Lang))
    const payload = serializer('session', {
      locale
    })
    return api('put', SEND_LANG, payload, rejectWithValue)
  }
)

export const sendTheme = createAsyncThunk<FetchResponse<PaletteType>, PaletteType, ErrorType>(
  'user/sendTheme',
  async (theme, { rejectWithValue }) => {
    const payload = serializer('session', {
      theme
    })
    return api('put', SEND_THEME, payload, rejectWithValue)
  }
)

export type GetContextResponse = {
  account: string
  eServices: string[]
  establishmentNumber: null | string
  locale: 'ar' | 'en'
  personalNumber: string | null
  scopes: string[]
  state: string
  theme: PaletteType
  userName: string | null
  workspace: string | null
  workspaceUrl: string | null,
  phoneNumber?: string,
  notificationEmail?: string
}

export const getContext = createAsyncThunk<FetchResponse<GetContextResponse>, void, ErrorType>(
  'user/getContext',
  async (_, { rejectWithValue }) => {
    return api('get', CONTEXT, {}, rejectWithValue)
  }
)

export const initialState = stateAdapter.getInitialState<UserState>({
  lang: 'ar', // can be ar || en
  theme: 'light',
  error: {},
  loading: true,
  userName: '',
  personalNumber: '',
  img: '',
  account: '',
  state: '',
  eServices: [],
  workspace: '',
  workspaceUrl: '',
  establishmentNumber: '',
  mobileIsOpen: false,
  phoneNumber: '',
  notificationEmail: '',
  contextFailed: false
})

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLang(state, action: PayloadAction<Lang>) {
      state.lang = action.payload
    },
    setTheme(state, action: PayloadAction<PaletteType>) {
      state.theme = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setError(state, action: PayloadAction<SerializedError>) {
      state.error = action.payload
    },
    setMobileIsOpen(state, action: PayloadAction<boolean>) {
      state.mobileIsOpen = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getContext.fulfilled, (state, action) => {
        state.error = initialState.error
        if (action.payload.data.locale) state.lang = action.payload.data.locale
        if (action.payload.data.theme) state.theme = action.payload.data.theme
        state.personalNumber = action.payload.data.personalNumber
        state.establishmentNumber = action.payload.data.establishmentNumber
        state.state = action.payload.data.state
        state.eServices = action.payload.data.eServices
        state.workspace = action.payload.data.workspace
        state.userName = action.payload.data.userName
        state.workspaceUrl = action.payload.data.workspaceUrl
        state.phoneNumber = action.payload.data.phoneNumber
        state.notificationEmail = action.payload.data.notificationEmail
        state.contextFailed = false
      })
      .addCase(getContext.rejected, (state,) => {
        state.loading = false
        state.contextFailed = true
      })
      .addCase(sendLang.fulfilled, (state) => {
        state.error = initialState.error
      })
      .addCase(sendLang.rejected, (state, action) => {
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
        state.loading = false
      })
      .addCase(sendTheme.fulfilled, (state, action) => {
        state.error = initialState.error
        state.theme = action.meta.arg
      })
      .addCase(sendTheme.rejected, (state, action) => {
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
        state.loading = false
      })
      .addCase(REHYDRATE, (state, action: OmitedRehydrateAction) => {
        if(action?.payload?.user){
          state.error = action.payload.user.error
          state.userName = action.payload.user.userName
          state.personalNumber = action.payload.user.personalNumber
          state.img = action.payload.user.img
          state.account = action.payload.user.account
          state.workspaceUrl = action.payload.user.workspaceUrl
          state.establishmentNumber = action.payload.user.establishmentNumber
          state.phoneNumber = action.payload.user.phoneNumber
          state.notificationEmail = action.payload.user.notificationEmail
        }
      })
  }
})

export const reducer: Reducer<typeof initialState> = user.reducer
export const { setLoading, setError, setTheme, setLang, setMobileIsOpen } = user.actions

