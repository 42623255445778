import {
  createAsyncThunk,
  createSlice,
  Reducer,
  createEntityAdapter,
  PayloadAction
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { INIT_SSO, LOGOUT, LOGOUT_SSO, VERIFY_TOKEN, VERIFY_TOKEN_SSO } from '../constants/api'
import { serializer } from '@takamol/unified-components'
import { ErrorType, FetchResponse, SerializedError } from '../@types'
import getEnvVar from '../utils/getEnvVar'
import { clearShowedWarning } from '../utils/correctOccupationWarning'
import { LOCAL_ENV } from '../constants/auth'

const stateAdapter = createEntityAdapter()

export const logout = createAsyncThunk<void, void, ErrorType>(
  'auth/logout',
  async (_, { rejectWithValue }) => api('post', LOGOUT, {}, rejectWithValue)
)

export const verifyToken = createAsyncThunk<void, string, ErrorType>(
  'auth/verifyToken',
  async (token, { rejectWithValue }) => {
    const payload = serializer('token', { token })
    return api('post', VERIFY_TOKEN, payload, rejectWithValue)
  }
)

type InitSSOResponse = {
  redirectUrl: string
}

type VerifySSOResponse = {
  afterAuthRedirectUrl: string
}

type InitSSOArgs = {
  redirectUrl: string
  state?: string
  needRedirect?: boolean
}

export const initSSO = createAsyncThunk<FetchResponse<InitSSOResponse>, InitSSOArgs, ErrorType>(
  'auth/initSSO',
  async ({ redirectUrl, state }, { dispatch, rejectWithValue }) => {
    dispatch(setInit(true))
    const payload = serializer('init', {
      redirectUrl,
      ...(state && { state }),
      ...(getEnvVar('REACT_APP_SPACE') === LOCAL_ENV && { local: true })
    })
    return api('post', INIT_SSO, payload, rejectWithValue)
  }
)

type VerifyTokenSSOArgs = {
  code: string
  state?: string
}

export const verifyTokenSSO = createAsyncThunk<FetchResponse<VerifySSOResponse>, VerifyTokenSSOArgs, ErrorType>(
  'auth/verifyTokenSSO',
  async ({ code, state }, { rejectWithValue }) => {
    const payload = serializer('session', { code, state })
    return api('post', VERIFY_TOKEN_SSO, payload, rejectWithValue)
  }
)

type LogoutSSOResponse = {
  redirectUrl: string
}

export const logoutSSO = createAsyncThunk<FetchResponse<LogoutSSOResponse>, void, ErrorType>(
  'auth/logoutSSO',
  async (_, { rejectWithValue }) => {
    return api('post', LOGOUT_SSO, {}, rejectWithValue)
  }
)

export type AuthState = {
  error: SerializedError
  loading: boolean
  init: boolean
  errorLogout: SerializedError
}

export const initialState = stateAdapter.getInitialState<AuthState>({
  error: {},
  loading: false,
  init: false,
  errorLogout: {}
})

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setInit(state, action: PayloadAction<boolean>) {
      state.init = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(verifyToken.fulfilled, (state) => {
        state.error = initialState.error
        state.loading = false
        clearShowedWarning()
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
        state.loading = false
      })
      .addCase(logout.fulfilled, (state) => {
        window.location.href = getEnvVar('REACT_APP_AUTH_SPA') + window.location.origin
        state.errorLogout = initialState.error
        state.loading = false
      })
      .addCase(logout.rejected, (state) => {
        state.errorLogout = {
          nameCode: 'modal.errorTitle',
          title: ''
        }
      })
      .addCase(initSSO.fulfilled, (state, action) => {
        state.error = initialState.error
        if(action.meta.arg.needRedirect) {
          window.location.href = action.payload.data.redirectUrl
        }
      })
      .addCase(verifyTokenSSO.fulfilled, (state, action) => {
        state.error = initialState.error
        window.location.href = action.payload.data.afterAuthRedirectUrl
        state.loading = false
        clearShowedWarning()
      })
      .addCase(verifyTokenSSO.rejected, (state, action) => {
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
        state.loading = false
      })
      .addCase(logoutSSO.fulfilled, (state, action) => {
        state.errorLogout = initialState.error
        state.loading = false
        state.init = false
        window.location.href = action.payload.data.redirectUrl
      })
      .addCase(logoutSSO.rejected, (state) => {
        state.errorLogout = {
          nameCode: 'modal.errorTitle',
          title: ''
        }
      })
  }
})

export const reducer: Reducer<typeof initialState> = auth.reducer
export const { setLoading, setInit } = auth.actions
