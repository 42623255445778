import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_REQUESTS } from '../constants/api'
import { IFilter, TableSort } from '@takamol/unified-components'
import { ErrorType, FetchResponse, Lookup, Meta, SerializedError } from '../@types'

export const initialFilters: IFilter = {
  'correctOccupationRequests.workerPersonalNumber': {
    value: '',
    action: 'like',
  },
  'correctOccupationRequests.workerName': {
    value: '',
    action: 'like',
  },
  'correctOccupationRequests.status': {
    value: null,
    action: 'eq',
  },
  'correctOccupationRequests.createdAt': {
    value: null,
    action: 'eq',
  },
  'oldOccupation.title': {
    value: '',
    action: 'like',
  },
  'newOccupation.title': {
    value: '',
    action: 'like',
  },
}

export type CorrectOccupationItem = {
  id: string
  workerName: string
  workerPersonalNumber: string
  creatorPersonalNumber: string
  status: string
  establishmentNumber: string
  oldOccupationCode: string
  newOccupationCode: string
  oldOccupation: Lookup
  newOccupation: Lookup
  createdAt: string
  updatedAt: string
}

type CorrectOccupationState = {
  error: SerializedError
  pageCount: number
  currentPage: number
  pageSize: number
  loading: boolean
  correctOccupationList: CorrectOccupationItem[]
  showFilters: boolean
  filters: IFilter
  sortModel: TableSort
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<CorrectOccupationState>({
  error: {},
  currentPage: 1,
  pageCount: 1,
  pageSize: 1,
  loading: false,
  correctOccupationList: [],
  showFilters: false,
  filters: initialFilters,
  sortModel: []
})

type FetchRequestOccupationListArgs = { currentPage: number, sort: string, filters: string }

export const fetchRequestOccupationList = createAsyncThunk<FetchResponse<CorrectOccupationItem[], Meta>,
  FetchRequestOccupationListArgs,
  ErrorType>(
    'correctOccupationRequests/fetchRequestOccupationList',
    ({
      currentPage,
      sort,
      filters
    }, { rejectWithValue }) => {
      return api('get', GET_REQUESTS(currentPage, sort, filters), {}, rejectWithValue)
    }
  )

const correctOccupation = createSlice({
  name: 'correctOccupation',
  initialState,
  reducers: {
    reset() {
      return initialState
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setFilters(state, action: PayloadAction<IFilter>) {
      state.currentPage = 1
      state.filters = action.payload
    },
    setShowFilters(state, action: PayloadAction<boolean>) {
      state.showFilters = action.payload
    },
    setSortModel(state, action: PayloadAction<TableSort>) {
      state.currentPage = 1
      state.sortModel = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRequestOccupationList.fulfilled, (state, action) => {
        state.loading = false
        state.error = initialState.error
        state.correctOccupationList = action.payload.data
        state.pageCount = action.payload.meta.page_count
      })
      .addCase(fetchRequestOccupationList.rejected, (state, action) => {
        state.loading = false
        state.correctOccupationList = []
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
  }
})

export const reducer: Reducer<typeof initialState> = correctOccupation.reducer;
export const {
  setLoading,
  setFilters,
  setCurrentPage,
  setShowFilters,
  reset,
  setSortModel
} = correctOccupation.actions;
