import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit'
import api from '../services/axios'
import {
  GET_REQUESTS_HISTORY,
  GET_WAGE_PROTECTION_CERTIFICATE,
  SUBMIT_CERTIFICATE
} from '../constants/api'
import { ErrorType, FetchResponse, Meta, SerializedError } from '../@types'
import { IFilter } from '@takamol/unified-components'

export const initialFilters: IFilter = {
  'certificateInfos.createdAt': {
    value: null,
    action: 'eq',
  },
  'certificateInfos.creatorPersonalNumber': {
    value: '',
    action: 'like'
  },
  'certificateInfos.number': {
    value: '',
    action: 'like'
  },
  'certificateInfos.expiredAt': {
    value: null,
    action: 'eq',
  },
  'certificateInfos.status': {
    value: null,
    action: 'eq',
  },
}

export type CertificateInfo = {
  id: string
  creatorPersonalNumber: string
  createdAt: string
  directTo: string
  expiredAt: string
  issuedAt: string
  number: string
  otherPurpose: string
  purpose: string
  status: string
  updatedAt: string | null
}

export const initialCertificateInfo = {
  id: '',
  creatorPersonalNumber: '',
  createdAt: '',
  directTo: '',
  expiredAt: '',
  issuedAt: '',
  number: '',
  otherPurpose: '',
  purpose: '',
  status: '',
  updatedAt: null
}

type WageProtectionInitialState = {
  establishmentNumber: string
  fileId: number | null
  nitaqatColor: string
  subEconomicActivity: string
  certificateInfo: CertificateInfo
  loading: boolean
  error: SerializedError
  requestsLoading: boolean
  requestsError: SerializedError
  requestsHistory: CertificateInfo[]
  currentPage: number
  pageSize: number
  pageCount: number
  filters: IFilter
  showFilters: boolean
  reload: boolean
  submitted: boolean
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<WageProtectionInitialState>({
  establishmentNumber: '',
  fileId: null,
  nitaqatColor: '',
  subEconomicActivity: '',
  certificateInfo: initialCertificateInfo,
  loading: false,
  error: {},
  requestsLoading: false,
  requestsError: {},
  requestsHistory: [],
  currentPage: 1,
  pageSize: 9,
  pageCount: 0,
  filters: initialFilters,
  showFilters: false,
  reload: false,
  submitted: false
})

type CertificateResponse = {
  establishmentNumber: string
  fileId: number
  nitaqatColor: string
  subEconomicActivity: string
  certificateInfo: CertificateInfo
}

export const submitCertificate = createAsyncThunk<FetchResponse<CertificateResponse>, void, ErrorType>(
  'wageProtection/submitCertificate',
  async (_, { rejectWithValue }) => {
    return api('post', SUBMIT_CERTIFICATE, {}, rejectWithValue)
  }
)

type GetRequestsHistoryProps = {
  currentPage: number
  sort: string
  filters: string
}

export const fetchRequestsHistory = createAsyncThunk<FetchResponse<CertificateInfo[], Meta>,
  GetRequestsHistoryProps,
  ErrorType>
  (
    'wageProtection/fetchRequestsHistory',
    async ({ currentPage, sort, filters }, { rejectWithValue }) => {
      return api('get', GET_REQUESTS_HISTORY(currentPage, sort, filters), {}, rejectWithValue)
    }
  )

export const fetchCertificate = createAsyncThunk<FetchResponse<CertificateResponse>, void, ErrorType>(
  'wageProtection/fetchCertificate',
  async (_, { rejectWithValue }) => {
    return api('get', GET_WAGE_PROTECTION_CERTIFICATE, {}, rejectWithValue)
  }
)

const wageProtection = createSlice({
  name: 'wageProtection',
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setRequestsLoading(state, action: PayloadAction<boolean>) {
      state.requestsLoading = action.payload
    },
    setFilters(state, action: PayloadAction<IFilter>) {
      state.currentPage = 1
      state.filters = action.payload
    },
    setShowFilters(state, action: PayloadAction<boolean>) {
      state.showFilters = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(submitCertificate.fulfilled, (state, action) => {
        state.error = action.payload.data.certificateInfo ? initialState.error : {
          nameCode: 'modal.errorTitle'
        }

        state.establishmentNumber = action.payload.data.establishmentNumber
        state.fileId = action.payload.data.fileId
        state.nitaqatColor = action.payload.data.nitaqatColor
        state.subEconomicActivity = action.payload.data.subEconomicActivity

        if (action.payload?.data?.certificateInfo) {
          state.certificateInfo = action.payload.data.certificateInfo
        }

        state.loading = false
        state.submitted = true
      })
      .addCase(submitCertificate.rejected, (state, action) => {
        state.loading = false
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
      .addCase(fetchRequestsHistory.fulfilled, (state, action) => {
        state.requestsLoading = false
        state.requestsError = initialState.error
        state.requestsHistory = action.payload.data
        state.pageCount = action.payload.meta.page_count
      })
      .addCase(fetchRequestsHistory.rejected, (state, action) => {
        state.requestsLoading = false
        state.requestsHistory = []
        state.requestsError = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
        state.pageCount = initialState.pageCount
      })
      .addCase(fetchCertificate.fulfilled, (state, action) => {
        state.error = initialState.error

        state.establishmentNumber = action.payload.data.establishmentNumber
        state.fileId = action.payload.data.fileId
        state.nitaqatColor = action.payload.data.nitaqatColor
        state.subEconomicActivity = action.payload.data.subEconomicActivity

        if (action.payload?.data?.certificateInfo) {
          state.certificateInfo = action.payload.data.certificateInfo
        }

        state.loading = false
      })
      .addCase(fetchCertificate.rejected, (state, action) => {
        state.loading = false
        if (!action.payload?.errors || action.payload.status === 500) {
          state.reload = true
        }
      })
  }
})

export const reducer: Reducer<typeof initialState> = wageProtection.reducer
export const {
  setError,
  setLoading,
  setCurrentPage,
  setRequestsLoading,
  setFilters,
  setShowFilters
} = wageProtection.actions
