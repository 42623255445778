import api from '../services/axios'
import { ResourceKey } from 'i18next'
import { AxiosResponse } from 'axios'
import { GET_TRANSLATIONS, GET_PROJECTS } from '../constants/api'
import i18n from '../localization/i18n'

type TranslationKey = {
  translation: ResourceKey
}

type Translation = {
  en: TranslationKey
  ar: TranslationKey
}

type ProjectVersion = {
  [projectId: string]: number
}

const UP = 4
const SHAREABLE = 6

const getDefaultFilters = (field: string, values: number[]) => values.reduce(
  (acc, item, index) => acc + `${index !== 0 
    ? '&' : ''}filters[${field}][eq][]=${item}`, '')

const setTranslations = (translations: Translation) => {
  let translationsKey: keyof typeof translations
  for (translationsKey in translations) {
    i18n.addResourceBundle(
      translationsKey,
      'translation',
      JSON.parse(String(translations[translationsKey])).translation,
      true,
      true)
  }
}

const getLocalTranslations = () => {
  const localTranslations = JSON.parse(String(localStorage.getItem('up-translations')))

  if(localTranslations){
    setTranslations(localTranslations)
  }
}

type Project = {
  id: string
  nameAr: string
  nameEn: string
  descriptionAr: string
  descriptionEn: string
  version: number
  createdAt: string
  updatedAt: string
}

export default async () => {
  try {
    const { data }: AxiosResponse<Project[]> = await api(
      'get',
      GET_PROJECTS(getDefaultFilters('id', [UP, SHAREABLE])))
    const localVersion: ProjectVersion = JSON.parse(String(localStorage.getItem('up-localization-version')))
    const serverVersion: ProjectVersion = data.reduce((acc, item) => ({ ...acc, [item.id]: item.version }), {})

    if(data.some(item => item.version > localVersion?.[item.id] || localVersion === null)){
      localStorage.setItem('up-localization-version', JSON.stringify(serverVersion))
      const { data: translations }: AxiosResponse<Translation> = await api(
        'get',
        GET_TRANSLATIONS(getDefaultFilters('project_id', [UP, SHAREABLE])),
        {},
        {},
        {},
        false)
      localStorage.setItem('up-translations', JSON.stringify(translations))

      return setTranslations(translations)
    }

    getLocalTranslations()
  }
  catch (e) {
    localStorage.removeItem('up-localization-version')
    getLocalTranslations()
  }
}