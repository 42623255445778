export enum KeyboardActions {
  Enter = 'Enter',
  Esc = 'Escape'
}

export enum NotificationStatus {
  read = 'read',
  unread = 'unread'
}

export enum eServiceCodes {
  saudizationCertificate = 'saudization-certificate',
  changeOccupation = 'change-occupation',
  correctOccupations = 'correct-occupations',
  workPermits = 'work-permits',
  visas = 'visas',
  ajeerWorkerTransfer = 'ajeer-worker-transfer',
  musanedPoints = 'musaned-points',
  musanedLicenseRenewal = 'musaned-license-renewal',
  musanedBulkVisas = 'musaned-bulk-visas',
  musanedNotices = 'musaned-notices',
  musanedPackages = 'musaned-packages',
  wageProtection = 'wage-protection',
  workerData = 'up-worker-data',
  dormitoryLicense = 'dormitory-licenses'
}