import React, { Suspense, useEffect, useMemo } from 'react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { createGenerateClassName } from '@material-ui/core'
import i18nInstance from '../localization/i18n'
import { Direction, jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { persistConfig, persistor, RootState, useAppDispatch, useAppSelector } from '../store/root'
import { createUpTheme, UPProvider } from '@takamol/unified-components'
import theme from '../theme'
import PagePreloader from './shared/PagePreloader'
import PermissionManager from './managers/permission/PermissionManager'
import ModalManager from './managers/modal/ModalManager'
import { AppWrapper, AvailableEnvironments, ColorMode, LanguageIdentifier } from '@takamol/common-design-system'
import useQuery from '../hooks/useQuery'
import { getContext, setLoading, UserState } from '../store/user'
import { unwrapResult } from '@reduxjs/toolkit'
import { purgeStoredState } from 'redux-persist'
import { fetchCompany } from '../store/company'
import { fetchWorkspaces, setSidebarWorkspaces } from '../store/changeWorkspace'
import setupAxiosInterceptors from '../services/interceptors'
import { setLoading as setAuthLoading, verifyToken, verifyTokenSSO } from '../store/auth'
import handlePush from '../utils/handlePush'
import getTranslations from '../utils/getTranslations'
import { FeatureNames, isFeatureAvailable } from '../utils/features'
import useZendeskSupport from '../hooks/useZendeskSupport'
import useHotjar from '../hooks/useHotjar'
import useContextErrorModal from '../hooks/useContextErrorModal'

// constants
import {
  ACCESS_DENIED,
  COMPANY_PROFILE,
  DASHBOARD,
  E_SERVICES,
  E_SERVICES_BUY_POINTS,
  E_SERVICES_CORRECT_OCCUPATION,
  E_SERVICES_CORRECT_OCCUPATION_REQUEST,
  E_SERVICES_POINTS,
  E_SERVICES_SAUDI_CERTIFICATE,
  E_SERVICES_WAGE_PROTECTION,
  ERROR_SESSION,
  EXPATS,
  INVOICES,
  MAIN_LOADER_PAGE,
  NOT_FOUND,
  NOTIFICATIONS,
  PROFILE,
  WORKSPACES,
  CHECK_WORKSPACES,
  E_SERVICES_WORKER_DATA,
  E_SERVICES_WORKER_DATA_SYNC,
  E_SERVICES_DORMITORY_LICENSE_REQUESTS,
  E_SERVICES_DORMITORY_LICENSE_CREATE,
  E_SERVICES_DORMITORY_LICENSE_DETAILS,
  E_SERVICES_DORMITORY_LICENSE_EDIT
} from '../constants/routes'
import { eServiceCodes } from '../@types/enums'
import { APP_SPACE } from '../constants/api'
import { GUEST, USER } from '../constants/roles'
import { CODE, REDIRECT, STATE, TOKEN } from '../constants/auth'
import contains from '../utils/contains'
import { handleReset, handleError } from '../utils/errorBoundary'

// pages
const ProfilePage = React.lazy(() => import('../pages/profile/ProfilePage'))
const ChangeWorkspace = React.lazy(() => import('../pages/ChangeWorkspace'))
const EServices = React.lazy(() => import('../pages/EServices'))
const Expats = React.lazy(() => import('../pages/Expats'))
const CompanyProfile = React.lazy(() => import('../pages/profile/EditCompanyProfilePage'))
const Invoices = React.lazy(() => import('../pages/invoice/Invoices'))
const InvoiceDetails = React.lazy(() => import('../pages/invoice/InvoiceDetails'))
const Points = React.lazy(() => import('../pages/points/Transactions'))
const BuyPoints = React.lazy(() => import('../pages/points/BuyPoints'))
const CorrectOccupation = React.lazy(() => import('../pages/correctOccupation/CorrectOccupation'))
const CorrectOccupationRequest = React.lazy(() => import('../pages/correctOccupation/CorrectOccupationRequest'))
const ErrorSessionPage = React.lazy(() => import('../pages/ErrorSessionPage'))
const NotFoundPage = React.lazy(() => import('../pages/NotFoundPage'))
const AccessDeniedPage = React.lazy(() => import('../pages/AccessDeniedPage'))
const SaudiCertificate = React.lazy(() => import('../pages/SaudiCertificate'))
const Notifications = React.lazy(() => import('../pages/Notifications'))
const MainLoaderPage = React.lazy(() => import('../pages/MainLoaderPage'))
const WageProtection = React.lazy(() => import('../pages/WageProtection'))
const CheckWorkspaces = React.lazy(() => import('../pages/CheckWorkspaces'))
const WorkerDataRequests = React.lazy(() => import('../pages/workerData/WorkerDataRequests'))
const WorkerDataSync = React.lazy(() => import('../pages/workerData/WorkerDataSync'))
const DormitoryLicense = React.lazy(() => import('../pages/dormitoryLicense/DormitoryLicense'))
const DormitoryLicenseCreate = React.lazy(() => import('../pages/dormitoryLicense/DormitoryLicenseCreate'))
const DormitoryLicenseDetails = React.lazy(() => import('../pages/dormitoryLicense/DormitoryLicenseDetails'))
const DormitoryLicenseEdit = React.lazy(() => import('../pages/dormitoryLicense/DormitoryLicenseEdit'))

const generateClassName = createGenerateClassName({
  productionPrefix: 'prod',
  seed: 'core',
})
const jss = create({ plugins: [ ...jssPreset().plugins, rtl() ] })
const url = window.location.origin + window.location.pathname

const App = () => {
  useZendeskSupport()
  useHotjar()
  useContextErrorModal()
  const { i18n } = useTranslation();
  const { lang, workspace, eServices, state } = useAppSelector((state: RootState) => state.user)
  const params = useQuery()
  const dispatch = useAppDispatch()
  const themeMode = useAppSelector((state: RootState) => state.user.theme)
  const dir: Direction = useMemo(() => (lang === 'ar' ? 'rtl' : 'ltr'), [ lang ])
  const actionTheme = useMemo(() => {
    const newTheme = Object.assign({ direction: dir }, theme)
    newTheme.UP.type = themeMode
    return createUpTheme(newTheme)
  }, [ dir, themeMode ])
  const authQueryParam = isFeatureAvailable(FeatureNames.laborSSO) ? CODE : TOKEN

  const handleContext = async () => {
    try {
      const result = await dispatch(getContext())
      const { data } = await unwrapResult(result)
      const storage = JSON.parse(localStorage.getItem('persist:root') ?? '{}')
      if (storage?.user) {
        const user: UserState = JSON.parse(storage.user)
        const shouldPurge = data.personalNumber !== user.personalNumber
          || data.establishmentNumber !== user.establishmentNumber
          || data.state !== user.state

        if (shouldPurge) {
          purgeStoredState(persistConfig)
        }
      }
      persistor.persist()

      return data.state === GUEST
    }
    catch (e) {
      return false
    }
  }

  const removeParamToken = () => {
    params.delete(authQueryParam)
    window.history.replaceState(null, '', url)
  }

  useEffect(() => {
    setupAxiosInterceptors();
    (async () => {
      localStorage.removeItem(authQueryParam)
      localStorage.removeItem(REDIRECT)
      localStorage.removeItem(STATE)
      let isGuest = false

      if (params.get(authQueryParam)) {
        isGuest = await handleContext()
        if(isGuest){
          try {
            dispatch(setAuthLoading(true))
            const result = isFeatureAvailable(FeatureNames.laborSSO)
              ? await dispatch(verifyTokenSSO({
                code: params.get(authQueryParam) as string,
                state: params.has(STATE) ? params.get(STATE) as string : undefined
              }))
              : await dispatch(verifyToken(params.get(authQueryParam) as string))
            await unwrapResult(result)
            removeParamToken()
            isGuest = false
          } catch (e) {
            localStorage.setItem(authQueryParam, params.get(authQueryParam) as string)

            if(params.has(STATE)) {
              localStorage.setItem(STATE, params.get(STATE) as string)
            }

            localStorage.setItem(REDIRECT, window.location.origin + window.location.pathname)
            dispatch(handlePush(ERROR_SESSION))
            dispatch(setLoading(false))
            return
          }
        } else {
          isGuest = true
          removeParamToken()
        }
      }
      if(!isGuest) await handleContext()
      dispatch(setLoading(false))
      dispatch(fetchCompany())
      dispatch(fetchWorkspaces(1))
        .then(unwrapResult)
        .then(({ data }) => {
          dispatch(setSidebarWorkspaces(data?.slice(0, 4)))
        })
        .catch(() => undefined)
      if(isFeatureAvailable(FeatureNames.localizationTabLink)){
        getTranslations()
      }
    })()
  }, [])

  useEffect(() => {
    i18n.changeLanguage(lang)
    document.body.setAttribute('dir', dir)
  }, [ lang, dir ])

  return (
    <AppWrapper
      language={lang as LanguageIdentifier}
      colorMode={actionTheme.UP.type as ColorMode}
      environment={APP_SPACE as AvailableEnvironments.mock}
      inboxRoute={NOTIFICATIONS}
      singleNotificationRoute={`${NOTIFICATIONS}?id=`}
      onReset={handleReset}
      onError={handleError}
    >
      <ThemeProvider theme={actionTheme}>
        <StylesProvider generateClassName={generateClassName} jss={jss}>
          <CssBaseline/>
          <UPProvider value={actionTheme}>
            <I18nextProvider i18n={i18nInstance}>
              <Suspense fallback={<PagePreloader/>}>
                <PermissionManager routes={[
                  {
                    path: MAIN_LOADER_PAGE,
                    permissions: [],
                    data: (): any => true,
                    rules: (): boolean => true,
                    component: MainLoaderPage
                  },
                  {
                    path: ERROR_SESSION,
                    permissions: [],
                    data: (): any => true,
                    rules: (): boolean => true,
                    component: ErrorSessionPage
                  },
                  {
                    path: NOT_FOUND,
                    permissions: [],
                    data: (): any => true,
                    rules: (): boolean => true,
                    component: NotFoundPage
                  },
                  {
                    path: ACCESS_DENIED,
                    permissions: [],
                    data: (): any => true,
                    rules: (): boolean => true,
                    component: AccessDeniedPage
                  },
                  {
                    path: COMPANY_PROFILE,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace,
                    component: CompanyProfile
                  },
                  {
                    path: WORKSPACES,
                    permissions: [],
                    data: (): any => true,
                    rules: (): boolean => true,
                    component: ChangeWorkspace
                  },
                  {
                    path: E_SERVICES_CORRECT_OCCUPATION,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.correctOccupations),
                    component: CorrectOccupation
                  },
                  {
                    path: E_SERVICES_CORRECT_OCCUPATION_REQUEST,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.correctOccupations),
                    component: CorrectOccupationRequest
                  },
                  {
                    path: EXPATS,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace,
                    component: Expats
                  },
                  {
                    path: INVOICES,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace,
                    component: Invoices
                  },
                  {
                    path: `${INVOICES}/:id`,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace,
                    component: InvoiceDetails
                  },
                  {
                    path: E_SERVICES_POINTS,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.musanedPoints),
                    component: Points
                  },
                  {
                    path: E_SERVICES_BUY_POINTS,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.musanedPoints),
                    component: BuyPoints
                  },
                  {
                    path: E_SERVICES_SAUDI_CERTIFICATE,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.saudizationCertificate),
                    component: SaudiCertificate
                  },
                  {
                    path: E_SERVICES,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace,
                    component: EServices
                  },
                  {
                    path: NOTIFICATIONS,
                    permissions: [],
                    data: (): any => true,
                    rules: (): boolean => !contains(state, [GUEST]),
                    component: Notifications
                  },
                  ...(isFeatureAvailable(FeatureNames.newProfile))
                    ? [{
                      path: PROFILE,
                      permissions: [],
                      data: (): any => true,
                      rules: (): boolean => !contains(state, [GUEST]),
                      component: ProfilePage
                    }]
                    : [{
                      path: DASHBOARD,
                      permissions: [USER],
                      data: (): any => true,
                      rules: (): boolean => !!workspace,
                      component: ProfilePage
                    }],
                  {
                    path: E_SERVICES_WAGE_PROTECTION,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.wageProtection),
                    component: WageProtection
                  },
                  {
                    path: CHECK_WORKSPACES,
                    permissions: [],
                    data: (): any => true,
                    rules: (): boolean => !contains(state, [GUEST]),
                    component: CheckWorkspaces
                  },
                  {
                    path: E_SERVICES_WORKER_DATA,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.workerData),
                    component: WorkerDataRequests
                  },
                  {
                    path: E_SERVICES_WORKER_DATA_SYNC,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.workerData),
                    component: WorkerDataSync
                  },
                  {
                    path: E_SERVICES_DORMITORY_LICENSE_REQUESTS,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.dormitoryLicense),
                    component: DormitoryLicense
                  },
                  {
                    path: E_SERVICES_DORMITORY_LICENSE_CREATE,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.dormitoryLicense),
                    component: DormitoryLicenseCreate
                  },
                  {
                    path: `${E_SERVICES_DORMITORY_LICENSE_DETAILS}/:id`,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.dormitoryLicense),
                    component: DormitoryLicenseDetails
                  },
                  {
                    path: `${E_SERVICES_DORMITORY_LICENSE_EDIT}/:id`,
                    permissions: [USER],
                    data: (): any => true,
                    rules: (): boolean => !!workspace && eServices.includes(eServiceCodes.dormitoryLicense),
                    component: DormitoryLicenseEdit
                  },
                ]}
                />
              </Suspense>
              <ModalManager/>
            </I18nextProvider>
          </UPProvider>
        </StylesProvider>
      </ThemeProvider>
    </AppWrapper>
  )
}

export default App
