import { createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'

export enum LocationType {
  googleArea = 'administrative_area_level_1',
  googleCity = 'locality',
  googleNeighborhood = 'neighborhood',
  googleNeighborhoodLvl1 = 'sublocality',
  googleNeighborhoodLvl2 = 'administrative_area_level_2'
}

export type LocationItem = {
  googleArea: string
  googleCity: string
  googleNeighborhood: string
}

export type Location = {
  en: LocationItem | null
  ar: LocationItem | null
}

type Position = {
  lat: number
  lng: number
}

type GoogleMapState = {
  location: Location
  position: Position | null
}

const initialLocation: Location = {
  en: null,
  ar: null
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<GoogleMapState>({
  location: initialLocation,
  position: null
})

const googleMap = createSlice({
  name: 'googleMap',
  initialState,
  reducers: {
    setLocation(state, action: PayloadAction<Location>) {
      state.location = action.payload
    },
    setPosition(state, action: PayloadAction<Position>) {
      state.position = action.payload
    },
    reset() {
      return initialState
    }
  }
})

export const reducer: Reducer<typeof initialState> = googleMap.reducer
export const { setLocation, setPosition, reset } = googleMap.actions
