import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_ESERVICES } from '../constants/api'
import { ErrorType, FetchResponse, SerializedError } from '../@types'
import { IFilter } from '@takamol/unified-components'

export const initialFilters: IFilter = {
  title: {
    value: '',
    action: 'like',
  }
}

type EServiceState = {
  error: SerializedError,
  loading: boolean
  eServicesLoading: boolean
  eServices: EService[]
  filters: IFilter
}

export type EService = {
  code: string
  tagId: string
  descriptionEn: string
  descriptionAr: string
  id: number
  link: string
  titleEn: string
  titleAr: string
  status: 'active' | 'disabled'
  disableReasonEn: string
  disableReasonAr: string
  shareable: boolean
  warning: boolean | null
  visible: boolean
}

export type SharedLinksResponse = {
  redirectUrl: string
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<EServiceState>({
  eServices: [],
  error: {},
  loading: true,
  eServicesLoading: true,
  filters: initialFilters
})

type fetchEservicesArgs = {
  filters: string
}

export const fetchEservices = createAsyncThunk<FetchResponse<EService[]>, fetchEservicesArgs, ErrorType>(
  'eservices/fetchEservices',
  async ({ filters }, { rejectWithValue }) => {
    return api('get', GET_ESERVICES(filters), {}, rejectWithValue)
  }
)

export const fetchSharedLinks = createAsyncThunk<FetchResponse<SharedLinksResponse>, string, ErrorType>(
  'eservices/fetchSharedLinks',
  async (url, { rejectWithValue }) => {
    return api('post', url, {}, rejectWithValue)
  }
)

const eservices = createSlice({
  name: 'eservices',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<SerializedError>) {
      state.error = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setEServicesLoading(state, action: PayloadAction<boolean>) {
      state.eServicesLoading = action.payload
    },
    setFilters(state, action: PayloadAction<IFilter>) {
      state.filters = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEservices.fulfilled, (state, action) => {
        state.error = initialState.error
        state.eServices = action.payload.data
        state.eServicesLoading = false
      })
      .addCase(fetchEservices.rejected, (state, action) => {
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
        state.eServicesLoading = false
        state.eServices = []
      })
  }
})

export const reducer: Reducer<typeof initialState> = eservices.reducer
export const { setError, setLoading, setEServicesLoading, setFilters } = eservices.actions
