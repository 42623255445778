import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import App from './components/App'
import { Provider } from 'react-redux'
import { store, history } from './store/root'
import { ConnectedRouter } from 'connected-react-router'
import './assets/main.scss'
import './services/sentry'
import { FeatureNames, isFeatureAvailable } from './utils/features'

document.body.classList.add(isFeatureAvailable(FeatureNames.loewNextFont) ? 'loew' : 'arial')

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App/>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
