import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_LOOKUPS } from '../constants/api'
import { ErrorType, FetchResponse, Meta, Lookup } from '../@types'
import i18n from 'i18next'
import filterLookupByTitle from '../utils/filterLookupByTitle'
import { RootState } from './root'
import { IFilter, filtersSerializer } from '@takamol/unified-components'

type OccupationsGender = { occupationFemale: '1'; occupationMale: '2' }

const genders: OccupationsGender = {
  'occupationFemale': '1',
  'occupationMale': '2'
}

const getLookupEntity = (entity: LookupEntities): LookupEntities => (
  entity === 'occupationFemale' || entity === 'occupationMale' ? 'occupations' : entity
)

export type LookupEntities =
  'gender'
  | 'genders'
  | 'nationality'
  | 'nationalities'
  | 'occupation'
  | 'occupations'
  | 'occupationMale'
  | 'occupationFemale'
  | 'region'
  | 'regions'
  | 'saudiBank'
  | 'origins'
  | 'religions'
  | 'cities'

export type RequestsLookupType = { url: string, entity: LookupEntities }[]

type Search = {
  value: string
  isDirty: boolean
}

export type LookupSearch = Record<LookupEntities, Search>
export type LookupFilters = Record<LookupEntities, IFilter>
export type LookupLoading = Record<LookupEntities, boolean>
export type LookupCurrentPage = Record<LookupEntities, number>
export type LookupPageCount = Record<LookupEntities, number>

export interface LookupFeminativeItem extends Lookup {
  titleFemaleAr: string
  titleFemaleEn: string
  titleMaleAr: string
  titleMaleEn: string
}

type TLookup = {
  gender: Lookup[]
  genders: Lookup[]
  nationality: Lookup[]
  nationalities: Lookup[]
  occupation: LookupFeminativeItem[]
  occupations: LookupFeminativeItem[]
  occupationMale: LookupFeminativeItem[]
  occupationFemale: LookupFeminativeItem[]
  region: Lookup[]
  regions: Lookup[]
  saudiBank: Lookup[]
  origins: Lookup[]
  religions: Lookup[]
  cities: Lookup[]
}

type LookupState = {
  lookup: TLookup
  search: LookupSearch
  loadings: LookupLoading
  currentPage: LookupCurrentPage
  pageCount: LookupPageCount
  filters: LookupFilters
}

type SetLookupSearchParams = { entity: LookupEntities, search: Search }

type SetCurrentPageParams = { entity: LookupEntities, currentPage: number }

type SetLookupParams = { entity: LookupEntities, lookups: Lookup[] | LookupFeminativeItem[] }

type SetLookupLoadingParams = { entity: LookupEntities, loading: boolean }

type SetLookupFiltersParams = { entity: LookupEntities, filters: IFilter }

const currentPage: LookupCurrentPage = {
  gender: 1,
  occupation: 1,
  nationality: 1,
  genders: 1,
  nationalities: 1,
  occupationFemale: 1,
  region: 1,
  regions: 1,
  occupationMale: 1,
  occupations: 1,
  saudiBank: 1,
  origins: 1,
  religions: 1,
  cities: 1
}

const loadings: LookupLoading = {
  gender: true,
  occupationMale: true,
  region: true,
  regions: true,
  saudiBank: true,
  occupations: true,
  genders: true,
  occupationFemale: true,
  nationalities: true,
  nationality: true,
  occupation: true,
  origins: true,
  religions: true,
  cities: true
}

const pageCount: LookupPageCount = {
  gender: 1,
  region: 1,
  regions: 1,
  occupationMale: 1,
  saudiBank: 1,
  occupations: 1,
  genders: 1,
  occupationFemale: 1,
  nationalities: 1,
  occupation: 1,
  nationality: 1,
  origins: 1,
  religions: 1,
  cities: 1
}

const filters: LookupFilters = {
  gender: {},
  region: {},
  regions: {},
  occupationMale: {},
  saudiBank: {},
  occupations: {},
  genders: {},
  occupationFemale: {},
  nationalities: {},
  occupation: {},
  nationality: {},
  origins: {},
  religions: {},
  cities: {}
}

const search: LookupSearch = {
  religions: {
    value: '',
    isDirty: false
  },
  nationality: {
    value: '',
    isDirty: false
  },
  occupation: {
    value: '',
    isDirty: false
  },
  genders: {
    value: '',
    isDirty: false
  },
  nationalities: {
    value: '',
    isDirty: false
  },
  occupationFemale: {
    value: '',
    isDirty: false
  },
  occupations: {
    value: '',
    isDirty: false
  },
  region: {
    value: '',
    isDirty: false
  },
  regions: {
    value: '',
    isDirty: false
  },
  saudiBank: {
    value: '',
    isDirty: false
  },
  occupationMale: {
    value: '',
    isDirty: false
  },
  gender: {
    value: '',
    isDirty: false
  },
  origins: {
    value: '',
    isDirty: false
  },
  cities: {
    value: '',
    isDirty: false
  }
}

type FetchLookupsParams = {
  url: string
  entity: LookupEntities
  currentPage: number
  filters?: string
}

export const fetchLookups = createAsyncThunk<FetchResponse<Lookup[] | LookupFeminativeItem[], Meta>,
  FetchLookupsParams,
  ErrorType>
  (
    'lookup/fetchLookups',
    async ({ url, entity, currentPage, filters }, { getState, rejectWithValue }) => {
      const state = getState() as RootState
      const { filters: defaultFilters } = state.lookup
      return api(
        'get',
        GET_LOOKUPS(
          url,
          getLookupEntity(entity),
          currentPage,
          filtersSerializer(defaultFilters[entity]) + filters,
          i18n.language,
          genders[entity as keyof typeof genders])
        ,
        {},
        rejectWithValue
      )
    })

export const searchLookups = createAsyncThunk<FetchResponse<Lookup[] | LookupFeminativeItem[], Meta>,
  FetchLookupsParams,
  ErrorType>
  (
    'lookup/searchLookups',
    async ({ url, entity, currentPage, filters }, { getState, rejectWithValue }) => {
      const state = getState() as RootState
      const { filters: defaultFilters } = state.lookup
      return api(
        'get',
        GET_LOOKUPS(
          url,
          getLookupEntity(entity),
          currentPage,
          filtersSerializer(defaultFilters[entity]) + filters,
          i18n.language,
          genders[entity as keyof typeof genders]
        ),
        {},
        rejectWithValue
      )
    })

type HandleFetchLookupsParams = {
  url: string
  entity: LookupEntities
  searchValue?: string
}

export const handleFetchLookups = createAsyncThunk<any, HandleFetchLookupsParams>(
  'lookup/handleFetchLookups',
  ({ url, entity, searchValue = '' }, { dispatch }) => {
    dispatch(searchLookups({ url, entity, currentPage: 1, filters: filterLookupByTitle(searchValue) }))
  }
)

type LoadMoreLookupsParams = {
  url: string
  entity: LookupEntities
}

export const loadMoreLookups = createAsyncThunk<any, LoadMoreLookupsParams>(
  'lookup/handleLoadMoreLookups',
  ({ url, entity }, { dispatch, getState }) => {
    const state = getState() as RootState
    const { currentPage, search, pageCount } = state.lookup
    const nextPage = currentPage[entity] + 1

    if (pageCount[entity] > currentPage[entity]) {
      dispatch(setCurrentPage({ entity, currentPage: nextPage }))
      dispatch(fetchLookups({ url, entity, currentPage: nextPage, filters: filterLookupByTitle(search[entity].value) }))
    }
  }
)

export const onSelectOpen = createAsyncThunk<any, HandleFetchLookupsParams>(
  'lookup/onSelectOpen',
  ({ url, entity, searchValue = '' }, { dispatch, getState }) => {
    const state = getState() as RootState
    const { lookup } = state.lookup
    if (!lookup[entity].length) dispatch(handleFetchLookups({ url, entity, searchValue }))
  }
)

export const onSelectClose = createAsyncThunk<any, HandleFetchLookupsParams>(
  'lookup/onSelectClose',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ url, entity, searchValue = '' }, { dispatch, getState }) => {
    const state = getState() as RootState
    const { search } = state.lookup
    if (search[entity].isDirty) {
      dispatch(setLookupSearch({ entity, search: { value: '', isDirty: false } }))
      dispatch(setCurrentPage({ entity, currentPage: 1 }))
      dispatch(setLookup({ entity, lookups: [] }))
      dispatch(setLookupLoading({ entity, loading: true }))
    }
  }
)

export const onSearchLookup = createAsyncThunk<any, HandleFetchLookupsParams>(
  'lookup/onSearchLookup',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ url, searchValue = '', entity }, { dispatch, getState }) => {
    const state = getState() as RootState
    const { loadings, lookup } = state.lookup
    if (!loadings[entity]) dispatch(setLookupLoading({ entity, loading: true }))
    if (lookup[entity].length) dispatch(setLookup({ entity, lookups: [] }))
    dispatch(setLookupSearch({ entity, search: { value: searchValue, isDirty: true } }))
  }
)

type HandleFilterLookupsParams = {
  url: string
  entity: LookupEntities,
  filters: IFilter
  reset?: boolean
  refetch?: boolean
}

export const onFilterLookup = createAsyncThunk<any, HandleFilterLookupsParams>(
  'lookup/onFilterLookup',
  ({ url, entity, filters, reset = false, refetch= false }, { dispatch, getState }) => {
    const state = getState() as RootState
    const { loadings } = state.lookup
    if (!loadings[entity]) dispatch(setLookupLoading({ entity, loading: true }))
    dispatch(setLookup({ entity, lookups: [] }))
    dispatch(setLookupFilters({ entity, filters: reset ? initialState.filters[entity] : filters }))
    if(refetch) dispatch(handleFetchLookups({ url, entity }))
  }
)

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<LookupState>({
  lookup: {
    gender: [],
    genders: [],
    nationality: [],
    nationalities: [],
    occupation: [],
    occupations: [],
    occupationMale: [],
    occupationFemale: [],
    region: [],
    regions: [],
    saudiBank: [],
    origins: [],
    religions: [],
    cities: []
  },
  search,
  loadings,
  currentPage,
  pageCount,
  filters
})

const lookup = createSlice({
  name: 'lookup',
  initialState,
  reducers: {
    setSearchValue(state: LookupState, action) {
      state.search = {
        ...state.search,
        [action.payload.name]: action.payload.value
      }
    },
    setCurrentPage(state, action: PayloadAction<SetCurrentPageParams>) {
      const { entity, currentPage } = action.payload
      state.currentPage[entity] = currentPage
    },
    resetCurrentPage(state) {
      state.currentPage = currentPage
    },
    setLookupSearch(state, action: PayloadAction<SetLookupSearchParams>) {
      const { search, entity } = action.payload
      state.search[entity] = search
    },
    setLookup(state, action: PayloadAction<SetLookupParams>) {
      const { entity, lookups } = action.payload
      // @ts-ignore
      state.lookup[entity] = lookups
    },
    setLookupLoading(state, action: PayloadAction<SetLookupLoadingParams>) {
      const { loading, entity } = action.payload
      state.loadings[entity] = loading
    },
    setLookupFilters(state, action: PayloadAction<SetLookupFiltersParams>) {
      const { entity, filters } = action.payload
      state.filters[entity] = filters
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchLookups.fulfilled, (state, action) => {
        const entity = action.meta.arg.entity
        // @ts-ignore
        state.lookup[entity] = [...state.lookup[entity], ...action.payload.data]
        state.loadings[entity] = false
        state.pageCount[entity] = action.payload.meta.page_count
      })
      .addCase(fetchLookups.rejected, (state, action) => {
        const entity = action.meta.arg.entity
        state.loadings[entity] = false
      })
      .addCase(searchLookups.fulfilled, (state, action) => {
        const entity = action.meta.arg.entity
        // @ts-ignore
        state.lookup[entity] = action.payload.data
        state.loadings[entity] = false
        state.pageCount[entity] = action.payload.meta.page_count
      })
      .addCase(searchLookups.rejected, (state, action) => {
        const entity = action.meta.arg.entity
        state.loadings[entity] = false
      })
  }
})

export const reducer: Reducer<typeof initialState> = lookup.reducer

export const {
  setSearchValue,
  setCurrentPage,
  resetCurrentPage,
  setLookupSearch,
  setLookupLoading,
  setLookup,
  setLookupFilters
} = lookup.actions
