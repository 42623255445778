import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer,
} from '@reduxjs/toolkit'
import React from 'react'

export const defaultHandler = () => true
export interface IModalManager {
  open?: boolean
  variant: 'confirm' | 'error' | 'success' | 'custom' | 'warning' | 'info'
  title?: string
  text?: string
  onClose?: () => void
  onConfirm?: () => void
  confirmBtnText?: string
  cancelBtnText?: string
  maxWidth?: number
  showIcon?: boolean
  children?: React.ReactNode
  overLapped?: boolean
}

const stateAdapter = createEntityAdapter()
export const initialState = stateAdapter.getInitialState<IModalManager>({
  open: false,
  variant: 'custom',
  title: '',
  text: '',
  onClose: defaultHandler,
  onConfirm: defaultHandler,
  confirmBtnText: '',
  cancelBtnText: '',
  showIcon: true,
  maxWidth: 560,
  children: null,
  overLapped: true
})

export const showModal = createAsyncThunk<IModalManager, IModalManager>(
  'modalManager/showModal',
  async (props) => props
)

const modalManager = createSlice({
  name: 'modalManager',
  initialState,
  reducers: {
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload
    },
    resetState(state) {
      state.open = false
      state.variant = 'custom'
      state.title = ''
      state.text = ''
      state.confirmBtnText = ''
      state.cancelBtnText = ''
      state.onConfirm = defaultHandler
      state.onClose = defaultHandler
      state.children = null
      state.showIcon = true
      state.maxWidth = 560
      state.overLapped = true
    },
  },
  extraReducers: builder => builder
    .addCase(showModal.fulfilled, (state, action) => {
      if(!state.overLapped){
        return
      }

      const {
        confirmBtnText,
        cancelBtnText,
        onConfirm,
        text,
        onClose,
        children,
        title,
        variant,
        showIcon = true,
        maxWidth = 560,
        overLapped = true
      } = action.payload
      state.open = true
      state.overLapped = overLapped
      state.variant = variant
      state.title = title
      state.text = text
      state.confirmBtnText = confirmBtnText
      state.cancelBtnText = cancelBtnText
      state.onConfirm = onConfirm
      state.onClose = onClose
      state.showIcon = showIcon
      state.maxWidth = maxWidth
      state.children = children
    })
})

export const reducer: Reducer<typeof initialState> = modalManager.reducer
export const { setOpen, resetState } = modalManager.actions

