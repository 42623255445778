export const UP_ESTABLISHMENT_BUCKET = 'up-establishment'
export const UP_FILES_BUCKET = 'up-files'

export type UploadFormDataProps = {
  file: File | Blob
  bucket: string
  prefix: string
  states: string
  access: string
  mode: string
  establishmentNumber: string
}

type UploadFormDataKeys = keyof UploadFormDataProps;
type UploadFormDataValues = UploadFormDataProps[UploadFormDataKeys]

export enum UploadFormDataAttributes {
  file = 'file',
  bucket = 'bucket',
  prefix = 'prefix',
  states = 'states',
  access = 'access',
  mode = 'mode',
  establishmentNumber = 'establishment_number'
}

export interface UploadFormData extends FormData {
  append(name: UploadFormDataAttributes, value: UploadFormDataValues, fileName?: string): void
}
