import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_AVAILABLE_OPTIONS, INIT_PAYMENT } from '../constants/api'
import { ErrorType, FetchResponse, SerializedError } from '../@types'
import { INVOICES } from '../constants/routes'
import { serializer } from '@takamol/unified-components'

const stateAdapter = createEntityAdapter()

export type AvailableOption = {
  amount: number
  amountWithVat: number
  createdAt: string
  id: number
  percentVat: number
  pointCount: number
  updatedAt: string
  valueVat: number
}

export type BillingType = 'sadad' | 'credit'

type SelectedOption = {
  productId: number | null
  redirectionUrl: string
  billingType: BillingType | null
  pointAmount: number
}

type BuyPointsState = {
  loading: boolean
  error: SerializedError
  paymentError: string
  availableOptions: AvailableOption[]
  selectedOption: SelectedOption
}

export const initialState = stateAdapter.getInitialState<BuyPointsState>({
  error: {},
  paymentError: '',
  loading: true,
  availableOptions: [],
  selectedOption: {
    productId: null,
    billingType: null,
    redirectionUrl: window.location.origin + INVOICES,
    pointAmount: 0
  }
})

export const fetchAvailableOptions = createAsyncThunk<FetchResponse<AvailableOption[]>,
  void,
  ErrorType>
  (
    'buyPoints/fetchAvailableOptions',
    (_, { rejectWithValue }) => {
      return api('get', GET_AVAILABLE_OPTIONS, {}, rejectWithValue)
    }
  )

export type InitPaymentResponse = {
  amount: string
  createdAt: string
  establishmentNumber: string
  id: number
  paymentId: string
  paymentUrl: string
  personalNumber: string
  productId: number
  redirectionUrl: string
  status: string
  updatedAt: string
}

export const initPayment = createAsyncThunk<FetchResponse<InitPaymentResponse>,
  SelectedOption,
  ErrorType>
  (
    'buyPoints/initPayment',
    (selectedOption, { rejectWithValue }) => {
      const payload = serializer('payment', selectedOption)
      return api('post', INIT_PAYMENT, payload, rejectWithValue)
    }
  )

const buyPoints = createSlice({
  name: 'buyPoints',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSelectedOption(state, action: PayloadAction<Partial<SelectedOption>>) {
      state.selectedOption = {
        ...state.selectedOption,
        ...action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAvailableOptions.fulfilled, (state, action) => {
        state.availableOptions = action.payload.data
        state.error = initialState.error
        state.loading = false
      })
      .addCase(fetchAvailableOptions.rejected, (state, action) => {
        state.loading = false
        state.error = { ...action.payload?.errors[0] }
      })
      .addCase(initPayment.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(initPayment.rejected, (state) => {
        state.loading = false
      })
  }
});

export const reducer: Reducer<typeof initialState> = buyPoints.reducer;
export const { setLoading, setSelectedOption } = buyPoints.actions;
