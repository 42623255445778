import Sentry from '../services/sentry'

export const handleError = (error: Error) => {
  if(Sentry.isInitialized()){
    Sentry.withScope((scope) => {
      scope.setTag('custom-type', 'error-boundary')
      Sentry.captureException(error)
    })
  }
}

export const handleReset = () => {
  window.location.reload()
}