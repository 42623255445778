import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Button, Modal, TextButton } from '@takamol/unified-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ActionIcon from './ActionIcon'
import { ReactComponent as ErrorIcon } from '../../../assets/images/ErrorIcon.svg'
import { ReactComponent as SuccessIcon } from '../../../assets/images/success.svg'
import { ReactComponent as WarningIcon } from '../../../assets/images/warning.svg'
import { ReactComponent as InfoIcon } from '../../../assets/images/info.svg'
import { useAppDispatch, useAppSelector } from '../../../store/root'
import { resetState } from '../../../store/modalManager'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    background: theme.UP.modal.background,
    padding: `${theme.spacing(6)}px ${theme.spacing(7)}px`,
    textAlign: 'center',

    '@media (max-width:375px)': {
      padding: `${theme.spacing(6)}px ${theme.spacing(3)}px`,
    }
  },
  iconWrapper: {
    marginBottom: theme.spacing(4),
  },
  title: {
    color: theme.UP.content.primary,
    marginBottom: theme.spacing(2),
  },
  text: {
    color: theme.UP.content.gray,
    marginBottom: theme.spacing(3),
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cancelBtn: {
    marginTop: theme.spacing(4)
  },
  infoIcon: {
    marginBottom: theme.spacing(2),
    height: '48px'
  }
}))

const icons = {
  confirm: <ActionIcon/>,
  error: <ErrorIcon data-testid='modal-error-icon'/>,
  success: <SuccessIcon data-testid='modal-success-icon'/>,
  warning: <WarningIcon data-testid='modal-warning-icon'/>,
  info: <InfoIcon data-testid='modal-info-icon'/>,
}

const ModalManager = () => {
  const {
    title,
    text,
    onClose,
    onConfirm,
    open = false,
    confirmBtnText,
    cancelBtnText,
    variant,
    children,
    showIcon,
    maxWidth
  } = useAppSelector(state => state.modalManager)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const handleConfirm = () => {
    dispatch(resetState())
    if(onConfirm) onConfirm()
  }

  const handleClose = () => {
    dispatch(resetState())
    if (onClose) onClose()
  }
  return (
    <Modal open={open} onClose={handleClose} maxWidth={maxWidth} testId='modal-wrapper'>
      <div data-testid='modal-component'>
        {variant !== 'custom'
          ? <Box className={classes.contentWrapper}>
            {showIcon && <Box className={classNames(classes.iconWrapper, { [classes.infoIcon]: variant === 'info' })}>
              {icons[variant]}
            </Box>}
            <Typography className={classes.title} variant='h1'>{title}</Typography>
            <Typography className={classes.text} variant='h4'>{text}</Typography>
            <Box className={classes.buttonsWrapper}>
              <Button
                id='confirmBtn'
                data-testid='modal-confirm-button'
                onClick={handleConfirm}
              >
                {confirmBtnText || t('buttons.ok')}
              </Button>
              {variant === 'confirm' && <TextButton id='cancelBtn' className={classes.cancelBtn} onClick={handleClose}>
                {cancelBtnText || t('buttons.cancel')}
              </TextButton>}
            </Box>
          </Box>
          : children ? children : null
        }
      </div>
    </Modal>
  )
}

export default ModalManager
