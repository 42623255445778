import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit'
import { serializer } from '@takamol/unified-components'
import api from '../services/axios'
import { GET_WORKSPACE, GET_WORKSPACES, SEND_WORKSPACE } from '../constants/api'
import { RootState } from './root'
import { ErrorType, FetchResponse, Meta, SerializedError } from '../@types'

export type SuspendStatus = 'active' | 'suspended' | 'unknown'

export type Workspace = {
  default: boolean
  establishmentNumber: string
  id: string
  laborOfficeId: number
  name: string
  sequenceNumber: number
  active: boolean
  activity: string
  type: string
  url: string
  suspensionStatus: SuspendStatus
  suspensionReasonEn?: string
  suspensionReasonAr?: string
}

type WorkspacesState = {
  sidebarWorkspaces: Workspace[]
  workspaces: Workspace[]
  currentWorkspace: Workspace
  selectedWorkspace: Workspace
  pageCount: number
  currentPage: number
  pageSize: number
  error: SerializedError
  sendWorkspaceError: SerializedError
  loading: boolean
  sendWorkspaceLoading: boolean
  currentWorkspaceLoading: boolean
  currentWorkspaceError: SerializedError
}

const stateAdapter = createEntityAdapter();

export const initialState = stateAdapter.getInitialState<WorkspacesState>({
  pageCount: 0,
  currentPage: 1,
  pageSize: 9,
  sidebarWorkspaces: [],
  workspaces: [],
  currentWorkspace: {} as Workspace,
  selectedWorkspace: {
    default: false,
    establishmentNumber: '',
    id: '',
    laborOfficeId: 0,
    name: '',
    sequenceNumber: 0,
    active: false,
    activity: '',
    type: '',
    url: '',
    suspensionStatus: 'unknown'
  },
  error: {},
  loading: false,
  sendWorkspaceLoading: false,
  sendWorkspaceError: {},
  currentWorkspaceLoading: false,
  currentWorkspaceError: {},
});

export const fetchWorkspaces = createAsyncThunk<FetchResponse<Workspace[], Meta>, number, ErrorType>(
  'changeWorkspace/fetchWorkspaces',
  async (page, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true))
    return api('get', GET_WORKSPACES(page), {}, rejectWithValue)
  }
)

export const fetchWorkspace = createAsyncThunk<FetchResponse<Workspace, Meta>, string, ErrorType>(
  'changeWorkspace/fetchWorkspace',
  async (id, { rejectWithValue }) => {
    return api('get', GET_WORKSPACE(id), {}, rejectWithValue)
  }
)

export const sendWorkspace = createAsyncThunk<void, string | undefined, ErrorType>(
  'changeWorkspace/sendWorkspace',
  async (establishmentNumber, { getState, dispatch, rejectWithValue }) => {
    dispatch(setSendWorkspaceLoading(true))
    const { changeWorkspace: { selectedWorkspace } } = getState() as RootState
    const payload = serializer('workspace', {
      ...(selectedWorkspace.id && { id: selectedWorkspace.id }),
      ...(establishmentNumber && { establishmentNumber })
    });
    return api('put', SEND_WORKSPACE, payload, rejectWithValue)
  }
)

const changeWorkspace = createSlice({
  name: 'changeWorkspace',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSendWorkspaceLoading(state, action: PayloadAction<boolean>) {
      state.sendWorkspaceLoading = action.payload
    },
    setSendWorkspaceError(state, action: PayloadAction<SerializedError>) {
      state.sendWorkspaceError = action.payload
    },
    setPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setSelectedWorkspace(state, action: PayloadAction<Workspace>) {
      state.selectedWorkspace = action.payload
    },
    setSidebarWorkspaces(state, action: PayloadAction<Workspace[]>) {
      state.sidebarWorkspaces = action.payload
    },
    setTotal(state, action: PayloadAction<number>) {
      state.pageCount = action.payload
    },
    setCurrentWorkspaceLoading(state, action: PayloadAction<boolean>){
      state.currentWorkspaceLoading = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWorkspaces.fulfilled, (state, action) => {
        state.error = initialState.error
        state.loading = false
        state.workspaces = action.payload.data
        state.pageCount = action.payload.meta.page_count
      })
      .addCase(fetchWorkspaces.rejected, (state, action) => {
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
        state.loading = false
      })
      .addCase(sendWorkspace.fulfilled, (state) => {
        state.sidebarWorkspaces = state.sidebarWorkspaces.map((item,) => ({
          ...item,
          active: item.establishmentNumber === state.selectedWorkspace.establishmentNumber
        }))
      })
      .addCase(sendWorkspace.rejected, (state, action) => {
        state.sendWorkspaceError = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
      .addCase(fetchWorkspace.fulfilled, (state, action) => {
        state.currentWorkspaceError = initialState.currentWorkspaceError
        state.currentWorkspace = action.payload.data
        state.currentWorkspaceLoading = false
      })
      .addCase(fetchWorkspace.rejected, (state, action) => {
        state.currentWorkspaceError = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
        state.currentWorkspaceLoading = false
      })
  }
});

export const reducer: Reducer<typeof initialState> = changeWorkspace.reducer;
export const {
  setPage,
  setSelectedWorkspace,
  setLoading,
  setTotal,
  setSendWorkspaceLoading,
  setSendWorkspaceError,
  setSidebarWorkspaces,
  setCurrentWorkspaceLoading
} = changeWorkspace.actions;

