import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { UPDATE_DORMITORY_LICENSE_REQUESTS, GET_DORMITORY_LICENSE_DETAILS } from '../constants/api'
import { ErrorType, FetchResponse, Lookup, Meta, SerializedError } from '../@types'
import { serializer } from '@takamol/unified-components'

const stateAdapter = createEntityAdapter()

type LicenseLocation = {
  latitude?: number
  longitude?: number
  googleAreaEn?: string
  googleAreaAr?: string
  googleCityEn?: string
  googleCityAr?: string
  googleNeighborhoodEn?: string
  googleNeighborhoodAr?: string
}

type DormitoryLicenseRequest = {
  id: string | number
  establishmentNumber: string
  status: string
  communityType: string
  supervisorName: string
  supervisorContactNumber: string
  area: string
  city: Lookup
  neighborhood: string
  areaCommunity: string
  capacity: number
  contractWithCompany: boolean
  contractCompanyName?: string
  contractFileId?: number
  contractFileName?: string
  licenseFleId: number
  licenseFileName: string
  licenseEndDate: string
  licenseStartDate: string
  declarationChecked: boolean
  createdAt: string | null
  updatedAt: string | null
  licenseLocation: LicenseLocation
}

export type CommunityType = {
  title: string
  value: string
}

type FormFile = {
  id: string | number
  name: string
}

export type City = Lookup & {
  regionCode: number | string
}

export type DormitoryLicenseForm = {
  id: number | string
  supervisorName: string
  supervisorContactNumber: string
  communityType: CommunityType | null
  area: string | Lookup
  city: City | null | Lookup
  neighborhood: string | Lookup
  areaCommunity: string
  capacity: number
  contractWithCompany: boolean
  contractCompanyName: string
  contractFile: FormFile | null
  licenseFile: FormFile | null
  licenseStartDate: string | null
  licenseEndDate: string | null
  declarationChecked: boolean
}

export type DormitoryLicenseInfo = {
  id: string | number
  establishmentNumber: string
  status: string
  communityType: string
  supervisorName: string
  supervisorContactNumber: string
  area: string
  city: Lookup
  neighborhood: string
  areaCommunity: string
  capacity: number
  contractWithCompany: boolean
  contractCompanyName?: string
  contractFileId?: number
  contractFileName?: string
  licenseFileId: number
  licenseFileName: string
  licenseEndDate: string
  licenseStartDate: string
  declarationChecked: boolean
  licenseLocation: LicenseLocation
  createdAt: string | null
  updatedAt: string | null
}

type DormitoryLicenseDetailsState = {
  info: DormitoryLicenseInfo
  error: SerializedError
  loading: boolean
  updateLoading: boolean
}

export const initialState = stateAdapter.getInitialState<DormitoryLicenseDetailsState>({
  info: {} as DormitoryLicenseInfo,
  error: {},
  loading: false,
  updateLoading: false
})

export const fetchDormitoryLicenseDetails = createAsyncThunk<FetchResponse<DormitoryLicenseInfo, Meta>,
  string | number,
  ErrorType>(
    'dormitoryLicenseDetails/fetchDormitoryLicenseDetails',
    async (id, { rejectWithValue }) => {
      return api('get', GET_DORMITORY_LICENSE_DETAILS(id), {}, rejectWithValue)
    }
  )

type UpdateDormitoryLicenseArgs = {
  id: string | number
  supervisorName: string
  supervisorContactNumber: string
  communityType?: string
  area?: string
  cityCode?: number
  neighborhood?: string
  areaCommunity: string
  capacity: number
  contractWithCompany: boolean
  contractCompanyName?: string
  contractFileId?: number
  contractFileName?: string
  licenseFileId: number
  licenseFileName: string
  licenseStartDate: string
  licenseEndDate: string
  declarationChecked: boolean
  latitude?: string
  longitude?: string
  googleAreaEn?: string
  googleAreaAr?: string
  googleCityEn?: string
  googleCityAr?: string
  googleNeighborhoodEn?: string
  googleNeighborhoodAr?: string
}

export const updateDormitoryLicense = createAsyncThunk<FetchResponse<DormitoryLicenseRequest>,
  UpdateDormitoryLicenseArgs,
  ErrorType>(
    'dormitoryLicenseDetails/updateDormitoryLicense',
    async ({ id, ...rest }, { rejectWithValue }) => {
      const payload = serializer('dormitory-license', {
        ...rest
      })

      return api('put', UPDATE_DORMITORY_LICENSE_REQUESTS(id), payload, rejectWithValue)
    }
  )

const dormitoryLicenseDetails = createSlice({
  name: 'dormitoryLicenseDetails',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setUpdateLoading(state, action: PayloadAction<boolean>) {
      state.updateLoading = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDormitoryLicenseDetails.fulfilled, (state, action) => {
        state.info = action.payload.data
        state.error = initialState.error
        state.loading = false
      })
      .addCase(fetchDormitoryLicenseDetails.rejected, (state, action) => {
        state.info = initialState.info
        state.loading = false
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
      .addCase(updateDormitoryLicense.fulfilled, (state) => {
        state.updateLoading = false
      })
      .addCase(updateDormitoryLicense.rejected, (state) => {
        state.updateLoading = false
      })
  }
});

export const reducer: Reducer<typeof initialState> = dormitoryLicenseDetails.reducer;
export const {
  setLoading,
  setUpdateLoading
} = dormitoryLicenseDetails.actions;
