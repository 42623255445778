import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_EXPATS } from '../constants/api'
import { ErrorType, FetchResponse, Lookup, Meta, SerializedError } from '../@types'
import { IFilter, TableSort } from '@takamol/unified-components'

export const initialFilters: IFilter = {
  'members.personalNumber': {
    value: '',
    action: 'like',
  },
  'members.names': {
    value: '',
    action: 'like',
  },
  'members.laborerStatusCode': {
    value: null,
    action: 'eq',
  },
  'nationality.title': {
    value: '',
    action: 'like',
  },
  'occupation.title': {
    value: '',
    action: 'like',
  },
  'workPermit.endDate': {
    value: null,
    action: 'eq',
  },
  'workPermit.status': {
    value: null,
    action: 'eq',
  },
}

const stateAdapter = createEntityAdapter();

export type Expat = {
  names: string
  personalNumber: string
  laborerStatusName: string
  laborerStatusCode: string
  workPermitEndDate: string
  jobName: string;
  nationalityName: string;
  workPermit: {
    status: string | null
  };
  gender: Lookup | null;
  religion: Lookup | null
  nationality: Lookup | null
  occupation: Lookup | null
  avatar: string | null
  jobCode: string;
  issuanceDate: string
  passportNo: string
  workPermitStatus?: string
  workPermitMonths?: string
}

export interface ExpatsState {
  expats: Expat[]
  error: SerializedError
  showFilters: boolean
  filters: IFilter
  pageCount: number
  currentPage: number
  pageSize: number
  loading: boolean
  sortModel: TableSort
}

export const initialState = stateAdapter.getInitialState<ExpatsState>({
  expats: [],
  pageCount: 1,
  currentPage: 1,
  pageSize: 9,
  error: {},
  loading: true,
  showFilters: false,
  filters: initialFilters,
  sortModel: []
})

type FetchExpatsArgs = {
  currentPage: number
  sort: string
  filters: string
}

export const fetchExpats = createAsyncThunk<FetchResponse<Expat[], Meta>,
  FetchExpatsArgs,
  ErrorType>
  (
    'expats/fetchExpats',
    async ({ currentPage, sort, filters }, { dispatch, rejectWithValue }) => {
      dispatch(setLoading(true))
      return api('get', GET_EXPATS(currentPage, sort, filters), {}, rejectWithValue)
    }
  )

const expats = createSlice({
  name: 'expats',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setFilters(state, action: PayloadAction<IFilter>) {
      state.currentPage = 1
      state.filters = action.payload
    },
    setShowFilters(state, action: PayloadAction<boolean>) {
      state.showFilters = action.payload
    },
    setSortModel(state, action: PayloadAction<TableSort>) {
      state.currentPage = 1
      state.sortModel = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchExpats.fulfilled, (state, action) => {
        state.expats = action.payload.data
        state.pageCount = action.payload?.meta?.page_count
        state.error = initialState.error
        state.loading = false
      })
      .addCase(fetchExpats.rejected, (state, action) => {
        state.loading = false
        state.expats = []
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
  }
})

export const {
  setLoading,
  setCurrentPage,
  setShowFilters,
  setFilters,
  setSortModel
} = expats.actions

export const reducer: Reducer<typeof initialState> = expats.reducer
