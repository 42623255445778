import { createEntityAdapter, createSlice, Reducer, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_INVOICE_DETAILS } from '../constants/api'
import { Invoice } from './invoices'
import { ErrorType, FetchResponse, SerializedError } from '../@types'

export type EstablishmentDetails = {
  establishmentNumber: string
  establishmentNameEn: string
  establishmentNameAr: string
  taxNumber: string
  profileCityName: string
  profileDistrict: string
  postalCode: string
  buildingNumber: string
  streetNameAr: string
  countryEn: string
  countryAr: string
  additionalNumber: string
  id: string
  cityEn: string
  cityAr: string
  street: string
  profileStreet: string
  profilePostalCode: string
}

export type SellerDetails = {
  establishmentName: string
  taxNumber: string
  buildingNumber: string
  streetNameEn: string
  streetNameAr: string
  districtEn: string
  districtAr: string
  cityEn: string
  cityAr: string
  countryEn: string
  countryAr: string
  postalCode: string
  additionalNumber: string
  id: string
}

export interface InvoiceDetails extends Invoice {
  seller: SellerDetails
  establishment: EstablishmentDetails
}

type InvoiceDetailsState = {
  invoice: InvoiceDetails
  loading: boolean
  error: SerializedError
}

const initialSeller: SellerDetails = {
  establishmentName: '',
  taxNumber: '',
  buildingNumber: '',
  streetNameEn: '',
  streetNameAr: '',
  districtEn: '',
  districtAr: '',
  cityEn: '',
  cityAr: '',
  countryEn: '',
  countryAr: '',
  postalCode: '',
  additionalNumber: '',
  id: ''
}

const initialEstablishment: EstablishmentDetails = {
  establishmentNumber: '',
  establishmentNameEn: '',
  establishmentNameAr: '',
  taxNumber: '',
  profileCityName: '',
  profileDistrict: '',
  postalCode: '',
  buildingNumber: '',
  streetNameAr: '',
  countryEn: '',
  countryAr: '',
  additionalNumber: '',
  id: '',
  cityEn: '',
  cityAr: '',
  street: '',
  profileStreet: '',
  profilePostalCode: ''
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<InvoiceDetailsState>({
  invoice: {
    status: 'new',
    amount: '',
    pointAmount: 0,
    price: '',
    tax: '',
    productId: '',
    entityId: null,
    entityName: '',
    redirectionUrl: '',
    serviceName: '',
    paymentUrl: '',
    paymentId: '',
    billingType: '',
    createdAt: '',
    purchasedAt: null,
    id: '',
    seller: initialSeller,
    establishment: initialEstablishment,
    pdfFileId: null
  },
  loading: true,
  error: {}
})

export const fetchInvoiceDetails = createAsyncThunk<FetchResponse<InvoiceDetails>, string, ErrorType>(
  'invoiceDetails/fetchInvoiceDetails',
  async (id: string, { rejectWithValue }) => {
    return api('get', GET_INVOICE_DETAILS(id), {}, rejectWithValue)
  }
)

const invoiceDetails = createSlice({
  name: 'invoiceDetails',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInvoiceDetails.fulfilled, (state, action) => {
        state.invoice = action.payload.data
        state.loading = false
        state.error = initialState.error
      })
      .addCase(fetchInvoiceDetails.rejected, (state, action) => {
        state.invoice = initialState.invoice
        state.loading = false
        state.error = { ...action.payload?.errors?.[0] }
      })
  }
})

export const reducer: Reducer<typeof initialState> = invoiceDetails.reducer
export const { setLoading } = invoiceDetails.actions
