export const CORE = 'core'
export const MUSANED = 'musaned'
export const SUPER_ADMIN = 'super-admin'
export const MUSANED_ADMIN = 'musaned-admin'
export const CORE_ADMIN = 'core-admin'
export const OPERATION_ADMIN = 'operation-admin'
export const MANDALORE_ADMIN = 'mandalore-admin'
export const AJEER_ADMIN = 'ajeer-admin'
export const MHRSD_ADMIN = 'mhrsd-admin'
export const SUPER_MHRSD_ADMIN = 'super-mhrsd-admin'
export const USER = 'user'
export const OPERATION = 'operation'
export const MHRSD = 'mhrsd'
export const GUEST = 'guest'
export const DORMITORY_ADMIN = 'dormitory-admin'
export const L0_ADMIN = 'l0-admin'
export const MHRSD_OPERATION_ADMIN = 'mhrsd-operation-admin'

export const ADMIN_LICENSE = {
  L1: 'musaned-license-request-reviewer-1l',
  L2: 'musaned-license-request-reviewer-2l',
  EXECUTION_MANAGER: 'musaned-license-execution-manager',
  INSPECTOR: 'musaned-license-inspector',
  MONITOR: 'musaned-license-monitor',
  MUSANED_ADMIN: 'musaned-license-admin',
  MUSANED_OPERATION: 'musaned-operation'
}

export const ADMIN_PACKAGE = {
  OBJECTION: 'musaned-objection-package-admin'
}

export enum AdminTypes {
  L1 = 'l1',
  L2 = 'l2',
}

export enum Roles {
  Admin = 'admin',
  User = 'user',
}
