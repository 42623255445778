import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { IFilter, TableSort } from '@takamol/unified-components'
import api from '../services/axios'
import { GET_TRANSACTIONS } from '../constants/api'
import { ErrorType, FetchResponse, Meta, SerializedError } from '../@types'

export type Transaction = {
  id: number
  type: string
  subtype: string
  createdAt: string
  value: string
  serviceName: string
  personalNumber: string
  balance: number
}

type TransactionsState = {
  loading: boolean
  error: SerializedError
  pageCount: number
  currentPage: number
  pageSize: number
  filters: IFilter
  showFilters: boolean
  transactions: Transaction[]
  sortModel: TableSort
}

const stateAdapter = createEntityAdapter()

export const initialFilters: IFilter = {
  'pointTransactions.createdAt': {
    value: {
      from: null,
      to: null
    },
    action: '',
  },
  'pointTransactions.serviceName': {
    value: null,
    action: 'eq',
  },
  'pointTransactions.subtype': {
    value: null,
    action: 'eq',
  },
  'pointTransactions.value': {
    value: {
      from: 0,
      to: 100000
    },
    action: '',
  }
}

export const initialState = stateAdapter.getInitialState<TransactionsState>({
  error: {},
  currentPage: 1,
  pageCount: 1,
  pageSize: 1,
  loading: true,
  filters: initialFilters,
  showFilters: false,
  transactions: [],
  sortModel: [ { id: 'pointTransactions.createdAt', desc: true } ]
})

type FetchTransactionsPayload = {
  currentPage: number
  sort: string
  filters: string
}

export const fetchTransactions = createAsyncThunk<FetchResponse<Transaction[], Meta>,
  FetchTransactionsPayload,
  ErrorType>(
    'transactions/fetchTransactions',
    ({ currentPage, sort, filters }, { rejectWithValue }) => {
      return api('get', GET_TRANSACTIONS(currentPage, sort, filters), {}, rejectWithValue)
    }
  )

const transactions = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setFilters(state, action: PayloadAction<IFilter>) {
      state.currentPage = 1
      state.filters = action.payload
    },
    setShowFilters(state, action: PayloadAction<boolean>) {
      state.showFilters = action.payload
    },
    setSortModel(state, action: PayloadAction<TableSort>) {
      state.currentPage = 1
      state.sortModel = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload.data
        state.error = initialState.error
        state.currentPage = action.payload.meta.current_page
        state.pageCount = action.payload.meta.page_count
        state.loading = false
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false
        state.error = { ...action.payload?.errors?.[0] }
      })
  }
});

export const reducer: Reducer<typeof initialState> = transactions.reducer;
export const { setLoading, setCurrentPage, setFilters, setShowFilters, setSortModel } = transactions.actions;
