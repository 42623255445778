import {
  createEntityAdapter,
  createSlice,
  Reducer,
  PayloadAction,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { IFilter } from '@takamol/unified-components'
import api from '../services/axios'
import { GET_WORKER_DATA_REQUESTS } from '../constants/api'
import { ErrorType, FetchResponse, Meta, SerializedError } from '../@types'

export const initialFilters: IFilter = {
  'syncMemberRequests.creatorPersonalNumber': {
    value: '',
    action: 'like',
  },
  'syncMemberRequests.personalNumber': {
    value: '',
    action: 'like',
  },
  'syncMemberRequests.createdAt': {
    value: {
      from: null,
      to: null
    },
    action: ''
  },
  'syncMemberRequests.syncedAt': {
    value: {
      from: null,
      to: null
    },
    action: ''
  },
  'syncMemberRequests.status': {
    value: null,
    action: 'eq',
  }
}

export type WorkerData = {
  id: string
  creatorPersonalNumber: string
  personalNumber: string
  establishmentNumber: string
  status: string
  createdAt: string
  syncedAt: string
}

type WorkerDataState = {
  requests: WorkerData[]
  filters: IFilter
  showFilters: boolean
  pageSize: number
  currentPage: number
  pageCount: number
  loading: boolean
  error: SerializedError
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<WorkerDataState>({
  requests: [],
  filters: initialFilters,
  showFilters: false,
  pageSize: 9,
  currentPage: 1,
  pageCount: 1,
  loading: false,
  error: {}
})

type FetchWorkerDataRequestsParams = {
  currentPage: number
  sort: string
  filters: string
}

export const fetchWorkerDataRequests = createAsyncThunk<FetchResponse<WorkerData[], Meta>,
  FetchWorkerDataRequestsParams,
  ErrorType>
  (
    'workerDataRequests/fetchWorkerDataRequests',
    async ({ currentPage, sort, filters }, { rejectWithValue }) => {
      return api('get', GET_WORKER_DATA_REQUESTS(currentPage, sort, filters), {}, rejectWithValue)
    }
  )

const workerDataRequests = createSlice({
  name: 'workerDataRequests',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<IFilter>) {
      state.currentPage = 1
      state.filters = action.payload
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setShowFilters(state, action: PayloadAction<boolean>) {
      state.showFilters = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWorkerDataRequests.fulfilled, (state, action) => {
        state.requests = action.payload.data
        state.pageCount = action.payload?.meta?.page_count
        state.loading = false
        state.error = initialState.error
      })
      .addCase(fetchWorkerDataRequests.rejected, (state, action) => {
        state.requests = []
        state.loading = false
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
  }
})

export const reducer: Reducer<typeof initialState> = workerDataRequests.reducer
export const { setFilters, setCurrentPage, setLoading, setShowFilters } = workerDataRequests.actions
