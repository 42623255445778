import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit'
import api from '../services/axios'
import {
  CREATE_DORMITORY_LICENSE_REQUESTS,
  DELETE_FILE,
  POST_FILE
} from '../constants/api'
import { getEnvVar, serializer } from '@takamol/unified-components'
import { ErrorType, FetchResponse, Lookup, SerializedError } from '../@types'
import { UP_ESTABLISHMENT_BUCKET, UP_FILES_BUCKET, UploadFormData, UploadFormDataAttributes } from 'constants/bucket'
import { MHRSD_ADMIN, DORMITORY_ADMIN } from '../constants/roles'

const stateAdapter = createEntityAdapter()

type LicenseLocation = {
  latitude?: number
  longitude?: number
  googleAreaEn?: string
  googleAreaAr?: string
  googleCityEn?: string
  googleCityAr?: string
  googleNeighborhoodEn?: string
  googleNeighborhoodAr?: string
}

type DormitoryLicenseRequest = {
  id: string | number
  establishmentNumber: string
  status: string
  communityType: string
  supervisorName: string
  supervisorContactNumber: string
  area: string
  city: Lookup
  neighborhood: string
  areaCommunity: string
  capacity: number
  contractWithCompany: boolean
  contractCompanyName?: string
  contractFileId?: number
  contractFileName?: string
  licenseFleId: number
  licenseFileName: string
  licenseEndDate: string
  licenseStartDate: string
  declarationChecked: boolean
  createdAt: string | null
  updatedAt: string | null
  licenseLocation: LicenseLocation
}

export enum UploadTypes {
  license = 'license',
  contract = 'contract'
}

export type Attachment = {
  id: string
  data: string
  filename: string
  generatedFilename?: string
  mimeType: string
  publicPath?: string
}

export const initialAttachment: Attachment = {
  id: '',
  data: '',
  filename: '',
  mimeType: ''
}

export type CommunityType = {
  title: string
  value: string
}

type FormFile = {
  id: string | number
  name: string
}

export type City = Lookup & {
  regionCode: number | string
}

export type DormitoryLicenseForm = {
  id: number | string
  supervisorName: string
  supervisorContactNumber: string
  communityType: CommunityType | null
  area: string | Lookup
  city: City | null | Lookup
  neighborhood: string | Lookup
  areaCommunity: string
  capacity: number
  contractWithCompany: boolean
  contractCompanyName: string
  contractFile: FormFile | null
  licenseFile: FormFile | null
  licenseStartDate: string | null
  licenseEndDate: string | null
  declarationChecked: boolean
}

type DormitoryLicenseState = {
  error: SerializedError
  pageCount: number
  currentPage: number
  pageSize: number
  loading: boolean
  uploadContractProgress: number
  uploadLicenseProgress: number
}

export const initialState = stateAdapter.getInitialState<DormitoryLicenseState>({
  error: {},
  pageCount: 1,
  currentPage: 1,
  pageSize: 9,
  loading: false,
  uploadContractProgress: 0,
  uploadLicenseProgress: 0
})

type CreateDormitoryLicenseArgs = {
  supervisorName: string
  supervisorContactNumber: string
  communityType?: string
  area?: string
  cityCode?: number
  neighborhood?: string
  areaCommunity: string
  capacity: number
  contractWithCompany: boolean
  contractCompanyName?: string
  contractFileId?: number
  contractFileName?: string
  licenseFileId: number
  licenseFileName: string
  licenseStartDate: string
  licenseEndDate: string
  declarationChecked: boolean
  latitude?: string
  longitude?: string
  googleAreaEn?: string
  googleAreaAr?: string
  googleCityEn?: string
  googleCityAr?: string
  googleNeighborhoodEn?: string
  googleNeighborhoodAr?: string
}

export const createDormitoryLicense = createAsyncThunk<FetchResponse<DormitoryLicenseRequest>,
  CreateDormitoryLicenseArgs,
  ErrorType>(
    'dormitoryLicenseCreate/createDormitoryLicense',
    async (data, { rejectWithValue }) => {
      const payload = serializer('dormitory-license', {
        ...data
      })
      return api('post', CREATE_DORMITORY_LICENSE_REQUESTS, payload, rejectWithValue)
    }
  )

type FileUploadArgs = {
  file: File
  name: string
}

export const fileUpload = createAsyncThunk<FetchResponse<Attachment>, FileUploadArgs, ErrorType>(
  'dormitoryLicenseCreate/fileUpload',
  async ({ file, name }, { dispatch, rejectWithValue }) => {
    const formData: UploadFormData = new FormData()
    formData.append(UploadFormDataAttributes.file, file)
    formData.append(UploadFormDataAttributes.prefix, UP_ESTABLISHMENT_BUCKET)
    formData.append(UploadFormDataAttributes.bucket, UP_FILES_BUCKET)
    formData.append(UploadFormDataAttributes.states, `${MHRSD_ADMIN} ${DORMITORY_ADMIN}`)

    return api(
      'post',
      POST_FILE,
      formData,
      rejectWithValue,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        ...(getEnvVar('NODE_ENV') !== 'test' && {
          onUploadProgress: (progressEvent: any) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            dispatch(setUploadProgress({ name, progress }))
          }
        }),
      }
    )
  }
)

export const deleteFile = createAsyncThunk<void, number, ErrorType>(
  'dormitoryLicenseCreate/deleteFile',
  async (id, { rejectWithValue }) => {
    return api('delete', DELETE_FILE(id), {}, rejectWithValue)
  }
)

const dormitoryLicenseCreate = createSlice({
  name: 'dormitoryLicenseCreate',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setUploadProgress(state, action: PayloadAction<{ name: string, progress: number }>) {
      if(action.payload.name === UploadTypes.contract) state.uploadContractProgress = action.payload.progress
      if(action.payload.name === UploadTypes.license) state.uploadLicenseProgress = action.payload.progress
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createDormitoryLicense.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(createDormitoryLicense.rejected, (state, action) => {
        state.loading = false
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
  }
});

export const reducer: Reducer<typeof initialState> = dormitoryLicenseCreate.reducer;
export const {
  setLoading,
  setUploadProgress
} = dormitoryLicenseCreate.actions;
