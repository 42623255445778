import { LookupEntities } from '../store/lookup'
import getEnvVar from '../utils/getEnvVar'
import { FeatureNames, isFeatureAvailable } from '../utils/features'
/* eslint-disable */
// common
export const GET_LOOKUPS = (
  // tslint:disable-next-line:max-line-length
  url: string, entity: LookupEntities, currentPage: number, filters: string | undefined = '', lang: string, gender?: '1' | '2'
) => `${url}${entity}?sort[title_${lang}]=asc&page_size=10&current_page=${currentPage}${filters}${gender ? `&gender_code=${gender}` : ''}`
export const GET_PRESIGNED_FILE = (id: number | string) => `/up-files/v4/files/${id}`
export const POST_FILE = '/up-files/v4/files'
export const DELETE_FILE = (id: number) => `/file-v3/${id}`
// Workspaces
export const GET_WORKSPACES = (page: number) => `/up-establishment/v2/workspaces?page_size=9&current_page=${page}`
export const GET_WORKSPACE = (id: string) => `/up-establishment/v2/workspaces/${id}`
export const SEND_WORKSPACE = isFeatureAvailable(FeatureNames.laborSSO) ? '/up-authentication/v4/session/workspace' : '/up-authentication/v3/session/workspace'
// session
export const VERIFY_TOKEN = '/up-authentication/v3/session'
export const INIT_SSO = '/up-authentication/v4/oauth/init'
export const VERIFY_TOKEN_SSO = '/up-authentication/v4/session'
// Logout
export const LOGOUT = '/logout'
export const LOGOUT_SSO = '/up-authentication/v4/logout'
// context
export const CONTEXT = '/up-gateway/v2/context'
// Company
export const ESTABLISHMENT = '/establishment/'
export const GET_COMPANY = '/context/establishment'
export const UPDATE_COMPANY = '/up-establishment/v2/establishments'
// Eservices
export const GET_ESERVICES = (filters = '') => `/up-e-service-v4/e-services?sort[position]=asc&page_size=100${filters}`
// Theme and language
export const SEND_THEME = isFeatureAvailable(FeatureNames.laborSSO) ? '/up-authentication/v4/session/theme' : '/session/theme'
export const SEND_LANG = isFeatureAvailable(FeatureNames.laborSSO) ? '/up-authentication/v4/session/locale' : '/session/locale'
// Saudization certificate
export const GET_CERTIFICATE = '/up-saudization/v3/certificate'
export const SEND_CERTIFICATE_REQUEST = isFeatureAvailable(FeatureNames.saudiCertificateV5Endpoints) ? '/up-saudization/v5/certificates' : '/up-saudization/v4/certificates'
// Wage Protection
export const GET_WAGE_PROTECTION_CERTIFICATE = '/up-wage-protection/v1/certificates'
export const SUBMIT_CERTIFICATE = '/up-wage-protection/v1/certificates'
export const GET_REQUESTS_HISTORY = (currentPage: number, sort: string, filters: string) =>  `/up-wage-protection/v1/certificate-infos?current_page=${currentPage}${sort}${filters}`
// Expats
export const GET_EXPATS = (currentPage: number, sort: string, filters: string) => `/context/establishment/expats?page_size=9&current_page=${currentPage}${sort}${filters}`
// Statistics
export const GET_COUNTRIES_STATISTICS = '/memberships/statistics/nationality'
export const GET_EXPATS_STATISTICS = '/memberships/statistics/expats-info'
export const GET_OCCUPATIONS_STATISTICS = '/memberships/statistics/occupation'
// Invoices
export const GET_INVOICES = (currentPage: number, sort: string, filters: string) => `/up-payment/v3/payments?page_size=9&current_page=${currentPage}${sort}${filters}`
export const GET_INVOICE_DETAILS = (id: string) => `/up-payment/v3/payments/${id}`
export const REFRESH_INVOICE = (paymentId: string) => `/up-payment/${isFeatureAvailable(FeatureNames.zatcaIntegration) ? 'v4' : 'v3'}/payments/${paymentId}/refresh`
export const GET_INVOICE_PDF = (id: number | string) => `/up-payment/v4/payments/${id}/pdf`
// Points
export const GET_TRANSACTIONS = (currentPage: number, sort: string, filters: string | undefined) => `/points/transactions?current_page=${currentPage}&filters[point_transactions.subtype][not_eq]=system${sort}${filters}`
export const GET_POINTS = '/points/wallet'
export const GET_AVAILABLE_OPTIONS = '/points/products'
export const INIT_PAYMENT = '/points/payment'
// Correct Occupation
export const GET_REQUESTS = (currentPage: number, sort: string, filters: string | undefined) => `/correct-occupation/requests?page_size=15&current_page=${currentPage}${sort}${filters}`
export const GET_NEW_OCCUPATION = (
  code: number | string, currentPage: number, filters: string | undefined = '', lang: string
) => `/correct-occupation/occupations?code=${code}&sort[title_${lang}]=asc&page_size=15&current_page=${currentPage}${filters}`
export const GET_WORKERS = (currentPage: number, sort: string, filters: string | undefined) => `/memberships/correct-occupation-requests/members?page_size=15&current_page=${currentPage}${sort}${filters}`
export const SEND_CORRECT_OCCUPATION_REQUEST = '/correct-occupation/requests'
// Payment Methods
export const GET_PAYMENT_METHODS = '/payment-methods'
// Translations
export const GET_PROJECTS = (filters: string) => `/up-translation-manager/projects?${filters}`
export const GET_TRANSLATIONS = (filters: string) => `/up-translation-manager/translations?${filters}`
// Export to excel
export const EXPAT_LIST_EXCEL = (filters: string, sort: string, filterByStatus?: boolean, workPermitTable: boolean = false) => `/memberships/expats/export-excel?excel_type=${workPermitTable ? 'work_permit_request_creation' : 'default'}${filters}${sort}${filterByStatus ? '&filters[laborer_status_code][eq]=1' : ''}`
export const INVOICE_LIST_EXCEL = (filters: string, sort: string) => `/up-payment/v3/payments/export-excel?${filters}${sort}`
export const TRANSACTION_LIST_EXCEL = (filters: string, sort: string) => `/points/transactions/export-excel?filters[subtype][not_eq]=system${filters}${sort}`
export const CORRECT_OCCUPATION_LIST_EXCEL = (filters: string, sort: string) => `/up-correct-occupation/v2/requests/export-excel?${filters}${sort}`
export const CORRECT_OCCUPATION_REQUEST_EXCEL = (filters: string, sort: string) => `/memberships/correct-occupation-requests/members/export-excel?${filters}${sort}`
// common team notifications
export const APP_SPACE = getEnvVar('REACT_APP_SPACE')
// Worker Data
export const GET_WORKER_DATA_REQUESTS = (currentPage: number, sort: string, filters: string) => `/up-activity-manager/v1/sync-member-requests?page_size=9&current_page=${currentPage}${sort}${filters}`
export const GET_WORKER_DATA_WORKERS = (filters: string) => `/context/establishment/expats?${filters}`
export const CREATE_WORKER_DATA_REQUESTS = '/up-activity-manager/v1/sync-member-requests'
// Dormitory License
export const GET_DORMITORY_LICENSE_REQUESTS = (currentPage: number, sort: string, filters: string | undefined) => `/up-establishment/v1/dormitory-licenses?page_size=9&current_page=${currentPage}${sort}${filters}`
export const GET_DORMITORY_LICENSE_DETAILS = (id: string | number) => `/up-establishment/v1/dormitory-licenses/${id}`
export const CREATE_DORMITORY_LICENSE_REQUESTS = isFeatureAvailable(FeatureNames.dormitoryGoogleMap) ? '/up-establishment/v2/dormitory-licenses' : '/up-establishment/v1/dormitory-licenses'
export const UPDATE_DORMITORY_LICENSE_REQUESTS = (id: string | number) => isFeatureAvailable(FeatureNames.dormitoryGoogleMap) ? `/up-establishment/v2/dormitory-licenses/${id}` : `/up-establishment/v1/dormitory-licenses/${id}`
export const DELETE_DORMITORY_LICENSE_REQUESTS = (id: string | number) => `/up-establishment/v1/dormitory-licenses/${id}`
/* eslint-enable */