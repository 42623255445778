import { createEntityAdapter, createAsyncThunk, createSlice, Reducer, PayloadAction } from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_COMPANY, UPDATE_COMPANY } from '../constants/api'
import { serializer } from '@takamol/unified-components'
import { REHYDRATE } from 'redux-persist/es/constants'
import { OmitedRehydrateAction } from './root'
import isEqual from 'lodash/isEqual'
import { ErrorType, FetchResponse, Lookup, SerializedError } from '../@types'
import { COMPANY_PROFILE } from '../constants/routes'

export type City = Lookup & {
  regionCode: number | string
}

type Entity = {
  establishmentId: number
  foreignersCount: string
  foreignersTotalFactorized: string
  id: number
  mhrsdEntityId: string
  saudisCount: string
  saudisTotalFactorized: string
  totalCount: string
}

type Nitaq = {
  colorCode: string
  colorId: string
  economicActivityId: string
  entityAudiPercentage: string
  establishmentId: number
  foreigners: string
  id: number
  nitaqatColor: string
  saudis: string
  sizeId: string
  sizeName: string
}

export type License = {
  establishmentId: number
  expiryDate: string
  id: number
  kind: string
  number: string
  startDate: string
  type: string
  withdrawalEndDate?: string
  withdrawalReason?: string
  withdrawalStartDate?: string
}

type CompanyInfo = {
  phoneCode: string
  profileContactNumber: string
  profileEstablishmentEmail: string
  taxNumber: string
  profileCityName: string
  profileDistrict: string
  cityCode: string
  cityName: string
  crEndDate: string
  crNumber: string
  crReleaseDate: string
  district: string
  establishmentEmail: string
  establishmentName: string
  establishmentNameEn: string
  establishmentNameAr: string
  establishmentNumber: string
  establishmentStatus: string
  establishmentStatusId: string
  establishmentTypeCode: string
  establishmentTypeName: string
  insertDate: string
  isMainBranch: boolean
  laborOfficeId: number
  mainEconomicActivity: string
  mainEconomicActivityId: string
  mhrsdEstablishmentId: number
  municipalLicenseEndDate: string
  municipalLicenseNumber: string
  municipalLicenseReleaseDate: string
  municipalLicenseSource: string
  nicAccountNumber: string
  notes: string
  ownerId: string
  pkOwnerId: string
  postalCode: string
  sequenceNumber: number
  sevenHundredNumber: string
  subEconomicActivity: string
  subEconomicActivityId: string
  street: string
  unifiedNationalNumber: string
  unifiedNumberId: string
  useUnifiedNationalNumberFlag: string
  zipCode: string
  nitaq: Nitaq
  entity: Entity
  license: License
  city: City | null
  region: Lookup | null
  error: SerializedError
  updateCompanyError: SerializedError
  updateCompanyLoading: boolean
  profileBuildingNumber: string
  profileStreet: string
  profilePostalCode: string
}

export type CompanyForm = {
  profileEstablishmentEmail: string
  profileContactNumber: string
  profileDistrict: string
  taxNumber: string
  cities: City | null
  regions: Lookup | null
  profileBuildingNumber: string
  profileStreet: string
  profilePostalCode: string
}

export type CompanyState = CompanyInfo & {
  loading: boolean
  error: SerializedError
  updateCompanyError: SerializedError
  updateCompanyLoading: boolean
  companyForm: CompanyForm
  showApplyChangesModal: boolean
  isTriggerError: boolean
}

export const initialCompanyForm: CompanyForm = {
  profileDistrict: '',
  profileContactNumber: '',
  profileEstablishmentEmail: '',
  taxNumber: '',
  cities: null,
  regions: null,
  profileBuildingNumber: '',
  profileStreet: '',
  profilePostalCode: ''
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<CompanyState>({
  phoneCode: '966',
  loading: true,
  profileContactNumber: '',
  profileEstablishmentEmail: '',
  taxNumber: '',
  profileCityName: '',
  profileDistrict: '',
  cityCode: '',
  cityName: '',
  crEndDate: '',
  crNumber: '',
  crReleaseDate: '',
  district: '',
  establishmentEmail: '',
  establishmentName: '',
  establishmentNameEn: '',
  establishmentNameAr: '',
  establishmentNumber: '',
  establishmentStatus: '',
  establishmentStatusId: '',
  establishmentTypeCode: '',
  establishmentTypeName: '',
  insertDate: '',
  isMainBranch: true,
  laborOfficeId: 0,
  mainEconomicActivity: '',
  mainEconomicActivityId: '',
  mhrsdEstablishmentId: 0,
  municipalLicenseEndDate: '',
  municipalLicenseNumber: '',
  municipalLicenseReleaseDate: '',
  municipalLicenseSource: '',
  nicAccountNumber: '',
  notes: '',
  ownerId: '',
  pkOwnerId: '',
  postalCode: '',
  sequenceNumber: 0,
  sevenHundredNumber: '',
  subEconomicActivity: '',
  subEconomicActivityId: '',
  street: '',
  unifiedNationalNumber: '',
  unifiedNumberId: '',
  useUnifiedNationalNumberFlag: '',
  zipCode: '',
  nitaq: {
    colorCode: '',
    colorId: '',
    economicActivityId: '',
    entityAudiPercentage: '',
    establishmentId: 0,
    foreigners: '',
    id: 0,
    nitaqatColor: '',
    saudis: '',
    sizeId: '',
    sizeName: ''
  },
  entity: {
    establishmentId: 0,
    foreignersCount: '',
    foreignersTotalFactorized: '',
    id: 0,
    mhrsdEntityId: '',
    saudisCount: '',
    saudisTotalFactorized: '',
    totalCount: ''
  },
  license: {
    establishmentId: 0,
    expiryDate: '',
    kind: '',
    id: 0,
    number: '',
    startDate: '',
    type: ''
  },
  city: null,
  region: null,
  error: {},
  profileBuildingNumber: '',
  profileStreet: '',
  profilePostalCode: '',
  updateCompanyError: {},
  updateCompanyLoading: false,
  companyForm: initialCompanyForm,
  showApplyChangesModal: false,
  isTriggerError: true
})

export const fetchCompany = createAsyncThunk<FetchResponse<CompanyInfo>, void, ErrorType>(
  'company/fetchCompany',
  async (_, { rejectWithValue }) => {
    return api('get', GET_COMPANY, {}, rejectWithValue)
  }
)

type UpdateCompanyArgs = {
  profileEstablishmentEmail: string
  profileContactNumber: string
  profileDistrict?: string
  taxNumber: string
  profileCityCode?: string
  profileRegionCode: string | null
  profileBuildingNumber: string
  profileStreet?: string
  profilePostalCode?: string
}

export const updateCompany = createAsyncThunk<FetchResponse<CompanyInfo>, UpdateCompanyArgs, ErrorType>(
  'company/updateCompany',
  async (value, { rejectWithValue }) => {
    const payload = serializer('establishment', value)
    return api('patch', UPDATE_COMPANY, payload, rejectWithValue)
  }
)

const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setUpdateCompanyError(state, action: PayloadAction<SerializedError>) {
      state.updateCompanyError = action.payload
    },
    setUpdateCompanyLoading(state, action: PayloadAction<boolean>) {
      state.updateCompanyLoading = action.payload
    },
    setCompanyForm(state, action: PayloadAction<CompanyForm>) {
      state.companyForm = action.payload
    },
    setShowApplyChangesModal(state, action: PayloadAction<boolean>) {
      state.showApplyChangesModal = action.payload
    },
    setIsTriggerError(state, action: PayloadAction<boolean>) {
      state.isTriggerError = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.loading = false
        state.error = initialState.error
        state.profileCityName = action.payload.data.profileCityName
        state.profileDistrict = action.payload.data.profileDistrict
        state.taxNumber = action.payload.data.taxNumber
        state.profileEstablishmentEmail = action.payload.data.profileEstablishmentEmail
        state.profileContactNumber = action.payload.data.profileContactNumber.length === 12
          ? action.payload.data.profileContactNumber.slice(3)
          : action.payload.data.profileContactNumber
        state.establishmentTypeName = action.payload.data.establishmentTypeName
        state.establishmentName = action.payload.data.establishmentName
        state.establishmentNameEn = action.payload.data.establishmentNameEn
        state.establishmentNameAr = action.payload.data.establishmentNameAr
        state.crNumber = action.payload.data.crNumber
        state.crEndDate = action.payload.data.crEndDate
        state.crReleaseDate = action.payload.data.crReleaseDate
        state.municipalLicenseReleaseDate = action.payload.data.municipalLicenseReleaseDate
        state.municipalLicenseEndDate = action.payload.data.municipalLicenseEndDate
        state.establishmentNumber = action.payload.data.establishmentNumber
        state.establishmentStatusId = action.payload.data.establishmentStatusId
        state.establishmentStatus = action.payload.data.establishmentStatus
        state.subEconomicActivity = action.payload.data.subEconomicActivity
        state.postalCode = action.payload.data.postalCode
        state.street = action.payload.data.street
        state.unifiedNumberId = action.payload.data.unifiedNumberId
        state.nitaq = action.payload.data.nitaq
        state.entity = action.payload.data.entity
        state.license = action.payload.data.license
        state.city = Number(action.payload.data.city?.id) !== 0
          ? action.payload.data.city
          : null
        state.region = Number(action.payload.data.region?.id) !== 0
          ? action.payload.data.region
          : null
        state.cityCode = action.payload.data.cityCode
        state.district = action.payload.data.district
        state.profileBuildingNumber = action.payload.data.profileBuildingNumber
        state.profileStreet = action.payload.data.profileStreet
        state.profilePostalCode = action.payload.data.profilePostalCode
      })
      .addCase(fetchCompany.rejected, (state) => {
        state.loading = false
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.updateCompanyLoading = false
        state.profileCityName = action.payload.data.profileCityName
        state.profileDistrict = action.payload.data.profileDistrict
        state.profileEstablishmentEmail = action.payload.data.profileEstablishmentEmail
        state.profileContactNumber = action.payload.data.profileContactNumber.length === 12
          ? action.payload.data.profileContactNumber.slice(3)
          : action.payload.data.profileContactNumber
        state.taxNumber = action.payload.data.taxNumber
        state.city = action.payload.data.city
        state.region = action.payload.data.region
        state.profileBuildingNumber = action.payload.data.profileBuildingNumber
        state.profileStreet = action.payload.data.profileStreet
        state.profilePostalCode = action.payload.data.profilePostalCode
      })
      .addCase(updateCompany.rejected, (state, action: any) => {
        state.updateCompanyLoading = false
        state.updateCompanyError = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
      .addCase(REHYDRATE, (state, action: OmitedRehydrateAction) => {
        if (action?.payload?.company) {
          const {
            companyForm,
            profileContactNumber,
            profileDistrict,
            profileEstablishmentEmail,
            taxNumber,
            city,
            region,
            profileBuildingNumber,
            profileStreet,
            profilePostalCode
          } = action?.payload?.company // eslint-disable-line no-unsafe-optional-chaining

          if(window.location.pathname !== COMPANY_PROFILE){
            return { ...initialState }
          }

          if (Object.values(companyForm).some((item) => (typeof item === 'string' ? !!item.length : !!item?.code))) {
            state.companyForm = companyForm
            state.isTriggerError = false
            state.showApplyChangesModal = !isEqual(companyForm, {
              profileContactNumber,
              profileDistrict,
              profileEstablishmentEmail,
              taxNumber,
              cities: city,
              regions: region,
              profileBuildingNumber,
              profileStreet,
              profilePostalCode
            })
          }
        }
      })
  }
});

export const reducer: Reducer<typeof initialState> = company.reducer
export const {
  setUpdateCompanyLoading,
  setUpdateCompanyError,
  setCompanyForm,
  setShowApplyChangesModal,
  setIsTriggerError
} = company.actions
