import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_CERTIFICATE, SEND_CERTIFICATE_REQUEST } from '../constants/api'
import { ErrorType, FetchResponse, SerializedError } from '../@types'
import { File } from '../hooks/useFileDownload'

type Certificate = {
  certificateNumber: string
  certificateIssuance: string
  certificateExpireDate: string
  certificateFileId: string
  certificateName: string
}

type SaudiCertificateInitialState = Certificate & {
  loading: boolean
  error: SerializedError
  reload: boolean
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<SaudiCertificateInitialState>({
  certificateNumber: '',
  certificateIssuance: '',
  certificateExpireDate: '',
  certificateFileId: '',
  certificateName: '',
  loading: false,
  error: {},
  reload: false,
})

type SaudiCertificateInfo = {
  number: string
  expiredAt: string
  issuedAt: string
}

type CertificateResponse = {
  establishment_number: string
  file: File
  saudiCertificateInfo: SaudiCertificateInfo
}

export const fetchCertificate = createAsyncThunk<FetchResponse<CertificateResponse>, void, ErrorType>(
  'saudiCertificate/fetchCertificate',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true))
    return api('get', GET_CERTIFICATE, {}, rejectWithValue)
  }
)

export const certificateRequest = createAsyncThunk<FetchResponse<CertificateResponse>, void, ErrorType>(
  'saudiCertificate/certificateRequest',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setError({}))
    dispatch(setLoading(true))
    return api('post', SEND_CERTIFICATE_REQUEST, {}, rejectWithValue)
  }
)

const saudiCertificate = createSlice({
  name: 'saudiCertificate',
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCertificate.fulfilled, (state, action) => {
        state.error = initialState.error
        state.certificateFileId = action.payload.data.file.id
        state.certificateName = action.payload.data.file.filename
        if (action.payload?.data?.saudiCertificateInfo) {
          state.certificateNumber = action.payload.data.saudiCertificateInfo.number
          state.certificateExpireDate = action.payload.data.saudiCertificateInfo.expiredAt
          state.certificateIssuance = action.payload.data.saudiCertificateInfo.issuedAt
        }
        state.loading = false
      })
      .addCase(fetchCertificate.rejected, (state, action) => {
        state.loading = false
        if (!action.payload?.errors || action.payload.status === 500) {
          state.reload = true
        }
      })
      .addCase(certificateRequest.fulfilled, (state, action) => {
        state.error = action.payload.data.saudiCertificateInfo ? initialState.error : {
          nameCode: 'modal.errorTitle'
        }
        state.certificateFileId = action.payload.data.file.id
        state.certificateName = action.payload.data.file.filename
        if (action.payload?.data?.saudiCertificateInfo) {
          state.certificateNumber = action.payload.data.saudiCertificateInfo.number
          state.certificateExpireDate = action.payload.data.saudiCertificateInfo.expiredAt
          state.certificateIssuance = action.payload.data.saudiCertificateInfo.issuedAt
        }
        state.loading = false
      })
      .addCase(certificateRequest.rejected, (state, action) => {
        state.loading = false
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
  }
})

export const reducer: Reducer<typeof initialState> = saudiCertificate.reducer
export const { setError, setLoading } = saudiCertificate.actions
