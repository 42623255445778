import { instance } from './axios'
import { showModal } from '../store/modalManager'
import { ExpireTokenModal } from '@takamol/unified-components'
import { store } from '../store/root'
import getEnvVar from '../utils/getEnvVar'
import { GUEST } from '../constants/roles'
import { FeatureNames, isFeatureAvailable } from '../utils/features'
import { initSSO, setInit } from '../store/auth'
import { unwrapResult } from '@reduxjs/toolkit'
import getErrorText from '../utils/getErrorText'
import { ErrorWithOutRedux } from '../@types'
import i18n from '../localization/i18n'
import { getContext } from '../store/user'

const renderExpireModal = (url: string) => {
  store.dispatch(showModal({
    variant: 'custom',
    maxWidth: 550,
    overLapped: false,
    children: <ExpireTokenModal redirectUrl={url}/>,
    onClose: () => {
      store.dispatch(setInit(false))
      window.location.href = url
    }
  }))
}

const LIMIT_OF_ATTEMPTS = 1
let attempts = 0

const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config: any) => {
    return config;
  };
  const onResponseSuccess = (response: any) => {
    attempts = 0;
    return response;
  };
  const onResponseError = async (err: any) => {
    if (err?.response?.status === 401 && !store.getState().auth.init) {
      const pathname = window.location.pathname
      const historyUrl = window.location.origin
        + (store.getState().user.state === GUEST ? window.history?.state?.state?.from ?? pathname : pathname)
      let URL = getEnvVar('REACT_APP_AUTH_SPA') + historyUrl
      if(isFeatureAvailable(FeatureNames.laborSSO)) {
        try {
          const response = await store.dispatch(initSSO({ redirectUrl: historyUrl }))
          const { data } = unwrapResult(response)
          URL = data.redirectUrl
          renderExpireModal(URL)
        }
        catch (e: unknown) {
          const error = e as ErrorWithOutRedux
          store.dispatch(showModal({
            variant: 'error',
            title: i18n.t('errors.errorName'),
            text: getErrorText({ ...error?.errors?.[0] }),
            overLapped: false,
            onConfirm: () => store.dispatch(setInit(false)),
            onClose: () => store.dispatch(setInit(false))
          }))
        }
      } else {
        renderExpireModal(URL)
      }
    }

    if (err?.response?.status === 403 && attempts < LIMIT_OF_ATTEMPTS) {
      attempts++
      await store.dispatch(getContext())
    }
    throw err;
  };
  instance.interceptors.request.use(onRequestSuccess);
  instance.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
