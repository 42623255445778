import getEnvVar from './getEnvVar'

export enum FeatureNames {
  withdrawalList = 'withdrawalList',
  expatCharts = 'expatCharts',
  zendeskSupport = 'zendeskSupport',
  exportToExcelExpats = 'exportToExcelExpats',
  localizationTabLink = 'localizationTabLink',
  zatcaIntegration = 'zatcaIntegration',
  sentryDsn = 'sentryDsn',
  sentryReplays = 'sentryReplays',
  laborSSO = 'laborSSO',
  exportToExcelCOCreate = 'exportToExcelCOCreate',
  loewNextFont = 'loewNextFont',
  breadcrumbs = 'breadcrumbs',
  newProfile = 'newProfile',
  saudiCertificateV5Endpoints = 'saudiCertificateV5Endpoints',
  wageProtection = 'wageProtection',
  freePointsText = 'freePointsText',
  commonLayout = 'commonLayout',
  migrationsTabLink = 'migrationsTabLink',
  mhrsdSuperAdminTabLink = 'mhrsdSuperAdminTabLink',
  dormitoryGoogleMap = 'dormitoryGoogleMap',
  commonApiLayout = 'commonApiLayout',
  featureTrue = 'featureTrue',
  featureFalse = 'featureFalse',
  dsLayoutOutlined = 'dsLayoutOutlined',
}

interface FeaturesConfig {
  [key: string]: string
}

export const FEATURES_CONFIG: FeaturesConfig = {
  [FeatureNames.withdrawalList]: getEnvVar('REACT_APP_WITHDRAWAL_LIST'),
  [FeatureNames.expatCharts]: getEnvVar('REACT_APP_EXPAT_CHARTS'),
  [FeatureNames.zendeskSupport]: getEnvVar('REACT_APP_ENABLE_ZENDESK'),
  [FeatureNames.exportToExcelExpats]: getEnvVar('REACT_APP_EXPORT_TO_EXCEL_EXPATS'),
  [FeatureNames.localizationTabLink]: getEnvVar('REACT_APP_LOCALIZATION_TAB_LINK'),
  [FeatureNames.zatcaIntegration]: getEnvVar('REACT_APP_ZATCA_INTEGRATION'),
  [FeatureNames.sentryDsn]: getEnvVar('REACT_APP_SENTRY_DSN'),
  [FeatureNames.sentryReplays]: getEnvVar('REACT_APP_SENTRY_REPLAYS'),
  [FeatureNames.laborSSO]: getEnvVar('REACT_APP_LABORER_SSO'),
  [FeatureNames.exportToExcelCOCreate]: getEnvVar('REACT_APP_EXPORT_TO_EXCEL_CO_CREATE'),
  [FeatureNames.loewNextFont]: getEnvVar('REACT_APP_LOEW_NEXT_FONT'),
  [FeatureNames.breadcrumbs]: getEnvVar('REACT_APP_BREADCRUMBS'),
  [FeatureNames.newProfile]: getEnvVar('REACT_APP_FLAG_NEW_PROFILE'),
  [FeatureNames.saudiCertificateV5Endpoints]: getEnvVar('REACT_APP_FLAG_SAUDI_CERTIFICATE_V5_ENDPOINTS'),
  [FeatureNames.wageProtection]: getEnvVar('REACT_APP_FLAG_WAGE_PROTECTION'),
  [FeatureNames.freePointsText]: getEnvVar('REACT_APP_FLAG_FREE_POINTS_TEXT'),
  [FeatureNames.commonLayout]: getEnvVar('REACT_APP_FLAG_COMMON_LAYOUT'),
  [FeatureNames.migrationsTabLink]: getEnvVar('REACT_APP_FLAG_MIGRATIONS_TAB_LINK'),
  [FeatureNames.mhrsdSuperAdminTabLink]: getEnvVar('REACT_APP_FLAG_MHRSD_SUPER_ADMIN_TAB_LINK'),
  [FeatureNames.dormitoryGoogleMap]: getEnvVar('REACT_APP_FLAG_DORMITORY_GOOGLE_MAP'),
  [FeatureNames.commonApiLayout]: getEnvVar('REACT_APP_FLAG_COMMON_API_LAYOUT'),
  [FeatureNames.featureTrue]: getEnvVar('REACT_APP_FEATURE_TRUE'),
  [FeatureNames.featureFalse]: getEnvVar('REACT_APP_FEATURE_FALSE'),
  [FeatureNames.dsLayoutOutlined]: getEnvVar('REACT_APP_FEATURE_DS_LAYOUT_OUTLINED'),
}

export const isFeatureAvailable = (name: FeatureNames): boolean => FEATURES_CONFIG[name] === 'true'
