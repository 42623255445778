export const CORRECT_OCCUPATION_CODE = 'correct-occupations'
export const CORRECT_OCCUPATION_LOCALSTORAGE = 'up-correct-occupation-warning'

type ShowedWarning = {
  isShowed: boolean,
  establishmentNumbers: string[] | null
}

export const getShowedWarning = (establishmentNumber: string | null): ShowedWarning => {
  const establishmentNumbers: string[] | null = JSON.parse(
    String(localStorage.getItem(CORRECT_OCCUPATION_LOCALSTORAGE)))
  return {
    isShowed: !establishmentNumbers
      ? false :
      establishmentNumbers.some((i: string) => String(i) === String(establishmentNumber)),
    establishmentNumbers
  }
}

export const setShowedWarning = (establishmentNumber: string | null, establishmentNumbers?: string[] | null) => {
  const numbers: string[] | null = establishmentNumbers ?? JSON.parse(
    String(localStorage.getItem(CORRECT_OCCUPATION_LOCALSTORAGE)))
  localStorage.setItem(CORRECT_OCCUPATION_LOCALSTORAGE, JSON.stringify([
    ...(numbers || []),
    establishmentNumber
  ]))
}

export const clearShowedWarning = () => {
  localStorage.removeItem(CORRECT_OCCUPATION_LOCALSTORAGE)
}