import {
  createEntityAdapter,
  createSlice,
  Reducer,
  PayloadAction,
  createAsyncThunk
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_PAYMENT_METHODS } from '../constants/api'
import { FetchResponse, ErrorType, Meta, SerializedError } from '../@types'
import { BillingType } from './buyPoints'

export enum PaymentMethodStatus {
  active = 'active',
  disabled = 'disabled',
}

export type PaymentMethod = {
  type: string,
  id: string,
  name: BillingType,
  imageUrl: string,
  status: PaymentMethodStatus,
  createAt: string,
  updatedAt: string,
  disableReasonEn: string,
  disableReasonAr: string
}

type PaymentMethodsState = {
  paymentMethods: PaymentMethod[]
  loading: boolean
  error: SerializedError
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<PaymentMethodsState>({
  paymentMethods: [],
  loading: true,
  error: {}
})

export const fetchPaymentMethods = createAsyncThunk<FetchResponse<PaymentMethod[], Meta>,
  void,
  ErrorType>(
    'paymentMethods/fetchPaymentMethods',
    (_, { rejectWithValue }) => {
      return api('get', GET_PAYMENT_METHODS, {}, rejectWithValue)
    }
  )

const paymentMethods = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods = action.payload.data
        state.loading = false
        state.error = initialState.error
      })
      .addCase(fetchPaymentMethods.rejected, (state, action) => {
        state.paymentMethods = []
        state.loading = false
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
  }
})

export const reducer: Reducer<typeof initialState> = paymentMethods.reducer
export const { setLoading } = paymentMethods.actions
