import { useAppSelector } from '../store/root'
import { useEffect } from 'react'
import { changeZendeskLang, setTraceId, zendeskInit } from '../utils/zendesk'
import { useTranslation } from 'react-i18next'

export default () => {
  const { i18n: { language } } = useTranslation()
  const { traceId } = useAppSelector(state => state.utils)

  useEffect(() => {
    zendeskInit()
  }, [])

  useEffect(() => {
    if (window.zE && traceId) {
      setTraceId(traceId)
    }
  }, [ traceId ])

  useEffect(() => {
    changeZendeskLang()
  }, [ language ])
}