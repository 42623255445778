import {
  createEntityAdapter,
  createSlice,
  Reducer,
  PayloadAction,
  createAsyncThunk
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_WORKER_DATA_WORKERS, CREATE_WORKER_DATA_REQUESTS } from '../constants/api'
import { ErrorType, FetchResponse, Lookup, Meta, SerializedError } from '../@types'
import { serializer } from '@takamol/unified-components'

export type WorkerDetails = {
  accommodationStatusCode: string
  accommodationStatusName: string
  borderNo: string
  educationalStatusCode: string
  educationalStatusName: string
  entryDate: string | null
  establishmentName: string
  establishmentNumber: string
  hijriDateOfBirth: string | null
  idReleaseDate: string
  isRegular: boolean
  jobCode: number
  jobName: string
  kingdomEntryDate: string | null
  laborOfficeId: number
  laborerStatusCode: string
  laborerStatusName: string
  mhrsdEstablishmentId: number
  names: string
  passportNo: string
  passportReleaseDate: string
  passportSource: string
  personalNumber: string
  pkLaborerId: number
  qualificationCode: string
  qualificationName: string
  sequenceNumber: number
  serviceEndDate: string | null
  serviceStartDate: string | null
  unifiedNumberId: number
  gender: string
  religion: Lookup | null
  nationality: Lookup | null
  occupation: Lookup | null
}

type WorkerDataSyncState = {
  workers: WorkerDetails[]
  selected: WorkerDetails
  loading: boolean
  error: SerializedError
  syncLoading: boolean
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<WorkerDataSyncState>({
  workers: [],
  selected: {} as WorkerDetails,
  loading: false,
  error: {},
  syncLoading: false
})

export const fetchWorkers = createAsyncThunk<FetchResponse<WorkerDetails[], Meta>,
  string,
  ErrorType>
  (
    'workerDataSync/fetchWorkers',
    async (filters, { rejectWithValue }) => {
      return api('get', GET_WORKER_DATA_WORKERS(filters), {}, rejectWithValue)
    }
  )

export const syncWorker = createAsyncThunk<void, WorkerDetails, ErrorType>(
  'workerDataSync/syncWorker',
  async (worker, { rejectWithValue }) => {
    const payload = serializer('sync-member-request', {
      personalNumber: worker.personalNumber
    })
    return api('post', CREATE_WORKER_DATA_REQUESTS, payload, rejectWithValue)
  }
)

const workerDataSync = createSlice({
  name: 'workerDataSync',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSelected(state, action: PayloadAction<WorkerDetails>) {
      state.selected = action.payload
    },
    setSyncLoading(state, action: PayloadAction<boolean>) {
      state.syncLoading = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWorkers.fulfilled, (state, action) => {
        state.workers = action.payload.data.slice(0, 3)
        state.loading = false
        state.error = initialState.error
      })
      .addCase(fetchWorkers.rejected, (state, action) => {
        state.workers = []
        state.loading = false
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
  }
})

export const reducer: Reducer<typeof initialState> = workerDataSync.reducer
export const { setLoading, setSelected, setSyncLoading } = workerDataSync.actions
