import { PaletteType } from '@material-ui/core'
import { FeatureNames, isFeatureAvailable } from './utils/features'

// A custom theme
const theme = {
  typography: {
    fontFamily: isFeatureAvailable(FeatureNames.loewNextFont)
      ? 'LoewMedium, LoewBold, sans-serif'
      : 'Arial, sans-serif',
  },
  UP: {
    seed: 'core',
    type: 'light' as PaletteType,
    background: {
      default: {
        light: '#F1F3F4',
        dark: '#191B21',
      },
      paper: {
        light: '#FFFFFF',
        dark: '#0C0E14',
      },
    },
    sidebar: {
      navigation: {
        active: {
          light: '#FFFFFF',
          dark: '#FFFFFF',
        },
        inactive: {
          light: '#B6CBD2',
          dark: '#00C6BD',
        },
      },
      background: {
        light: '#0D5068',
        dark: '#0C0E14',
      },
      switcher: {
        light: '#FFFFFF',
        dark: '#24262B',
      },
    },
    modal: {
      background: {
        light: '#FFFFFF',
        dark: '#272B36',
      },
      layout: {
        light: 'rgba(3, 20, 56, 0.6)',
        dark: 'rgba(12, 14, 20, 0.6)',
      },
    },
    content: {
      primary: {
        light: '#0D5068',
        dark: '#FFFFFF',
      },
      secondary: {
        light: '#105E66',
        dark: '#7C9DA2',
      },
      background: {
        light: '#F8F9FA',
        dark: '#24262B',
      },
      border: {
        light: '#E9EDF3',
        dark: '#45474B',
      },
      success: {
        light: '#4CC18B',
        dark: '#4CC18B',
      },
      error: {
        light: '#C4262D',
        dark: '#C4262D',
      },
      gray: {
        light: '#6C7293',
        dark: '#CFE8FF',
      },
      lightGray: {
        light: '#E9EDF3',
        dark: '#24262B',
      },
      paleGray: {
        light: '#FBFBFD',
        dark: '#181A20',
      },
    },
    common: {
      alternativeBlue: '#8590B3',
      alternativeGreen: {
        light: '#105E66',
        dark: '#7C9DA2'
      },
      alternativeGreenLight: '#105E66',
      alternativeGreenDark: '#7C9DA2',
      grayDark: '#6E7880',
      white: '#FFFFFF',
      black: '#000000',
      green: {
        light: '#4CC18B',
        dark: '#105E66'
      },
      greenLight: '#4CC18B',
      greenDark: '#105E66',
      gray: '#6C7293',
    },
    controls: {
      pagination: {
        items: {
          light: '#B9BABF',
          dark: '#5C656C'
        }
      },
      input: {
        text: {
          default: { light: '#0D5068', dark: '#FFFFFF' },
          disabled: { light: '#0D5068', dark: '#FFFFFF' },
        },
        background: {
          default: { light: '#FFFFFF', dark: '#272B36' },
          disabled: { light: '#F8F9FA', dark: '#181A20' },
        },
        border: {
          active: { light: '#7EC0FD', dark: '#45474B' },
          inactive: { light: '#E9EDF3', dark: '#45474B' },
          disabled: { light: '#E9EDF3', dark: '#45474B' },
        },
      },
      button: {
        background: {
          default: { light: '#4CC18B', dark: '#4CC18B' },
          disabled: { light: '#E9EDF3', dark: '#181A20' },
        },
        text: {
          default: { light: '#FFFFFF', dark: '#FFFFFF' },
          disabled: { light: '#A1A5B8', dark: '#A1A5B8' }
        }
      },
      dropdown: {},
      error: {
        text: '#C4262D',
        border: '#C4262D',
        background: '#C4262D',
      },
      success: {
        text: '#4CC18B',
        border: '#4CC18B',
        background: '#4CC18B',
      },
      charts: {
        primary: {
          light: { startColor: '#23B899', stopColor: '#23B899' },
          dark: { startColor: '#97577C', stopColor: '#773D5F' },
        },
        secondary: {
          light: { startColor: '#387DFF', stopColor: '#80ACFF' },
          dark: { startColor: '#39354E', stopColor: '#252235' },
        },
        dashedLine: {
          light: '#EAEBF0',
          dark: '#45474B',
        },
      },
    },
  },
}

export default theme
