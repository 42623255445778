import React from 'react'
import { ReactComponent as ConfirmActionIcon } from '../../../assets/images/confirmActionIcon.svg'
import { ReactComponent as ConfirmActionIconDark } from '../../../assets/images/confirmActionIconDark.svg'
import { useTheme } from '@material-ui/core'

const ActionIcon = () => {
  const theme = useTheme()
  return (
    <>
      {theme.UP.type === 'light'
        ? <ConfirmActionIcon data-testid='confirm-action-icon'/>
        : <ConfirmActionIconDark data-testid='confirm-action-icon-dark'/>
      }
    </>
  );
};

export default ActionIcon;
