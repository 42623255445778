import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { GET_COUNTRIES_STATISTICS, GET_EXPATS_STATISTICS, GET_OCCUPATIONS_STATISTICS } from '../constants/api'
import { ErrorType, FetchResponse, Meta, SerializedError } from '../@types'

const stateAdapter = createEntityAdapter();

const initialExpat: Expat = {
  id: '',
  maleGroups: [],
  femaleGroups: []
}

const initialOccupation: Occupation = {
  id: '',
  expatsCount: 0,
  memberGroups: []
}

export type Country = {
  id: string
  titleEn: string
  titleAr: string
  expatsCount: number
}

export type Group = {
  id: string
  ageRange: string
  expatsCount: number
}

export type Expat = {
  id: string
  maleGroups: Group[]
  femaleGroups: Group[]
}

export type Member = {
  id: string
  titleEn: string
  titleAr: string
  expatsCount: number
}

export type Occupation = {
  id: string
  expatsCount: number
  memberGroups: Member[]
}

export interface ExpatsState {
  errorCountries: SerializedError
  errorExpats: SerializedError,
  errorOccupations: SerializedError,
  loadingCountries: boolean
  loadingExpats: boolean
  loadingOccupations: boolean
  countries: Country[]
  expat: Expat
  occupation: Occupation
}

export const initialState = stateAdapter.getInitialState<ExpatsState>({
  errorCountries: {},
  errorExpats: {},
  errorOccupations: {},
  loadingCountries: false,
  loadingExpats: false,
  loadingOccupations: false,
  countries: [],
  expat: initialExpat,
  occupation: initialOccupation
})

export const fetchCountries = createAsyncThunk<FetchResponse<Country[], Meta>,
  void,
  ErrorType>
  (
    'statistics/fetchCountries',
    (_, { rejectWithValue }) => {
      return  api('get', GET_COUNTRIES_STATISTICS, {}, rejectWithValue)
    }
  )

export const fetchExpats = createAsyncThunk<FetchResponse<Expat, Meta>,
  void,
  ErrorType>
  (
    'statistics/fetchExpats',
    (_, { rejectWithValue }) => {
      return  api('get', GET_EXPATS_STATISTICS, {}, rejectWithValue)
    }
  )

export const fetchOccupations = createAsyncThunk<FetchResponse<Occupation, Meta>,
  void,
  ErrorType>
  (
    'statistics/fetchOccupations',
    (_, { rejectWithValue }) => {
      return  api('get', GET_OCCUPATIONS_STATISTICS, {}, rejectWithValue)
    }
  )

const statistics = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setLoadingCountries(state, action: PayloadAction<boolean>) {
      state.loadingCountries = action.payload
    },
    setLoadingExpats(state, action: PayloadAction<boolean>) {
      state.loadingExpats = action.payload
    },
    setLoadingOccupations(state, action: PayloadAction<boolean>) {
      state.loadingOccupations = action.payload
    },
    resetCountriesErrors(state) {
      state.errorCountries = initialState.errorCountries
    },
    resetExpatsErrors(state) {
      state.errorExpats = initialState.errorExpats
    },
    resetOccupationsErrors(state) {
      state.errorOccupations = initialState.errorOccupations
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries = action.payload.data
        state.errorCountries = initialState.errorCountries
        state.loadingCountries = false
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.loadingCountries = false
        state.countries = []
        state.errorCountries = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
      .addCase(fetchExpats.fulfilled, (state, action) => {
        state.expat = action.payload.data
        state.errorExpats = initialState.errorCountries
        state.loadingExpats = false
      })
      .addCase(fetchExpats.rejected, (state, action) => {
        state.loadingExpats = false
        state.expat = initialState.expat
        state.errorExpats = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
      .addCase(fetchOccupations.fulfilled, (state, action) => {
        state.occupation = action.payload.data
        state.errorOccupations = initialState.errorOccupations
        state.loadingOccupations = false
      })
      .addCase(fetchOccupations.rejected, (state, action) => {
        state.loadingOccupations = false
        state.occupation = initialState.occupation
        state.errorOccupations = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
  }
})

export const {
  setLoadingCountries,
  setLoadingExpats,
  setLoadingOccupations,
  resetCountriesErrors,
  resetExpatsErrors,
  resetOccupationsErrors
} = statistics.actions

export const reducer: Reducer<typeof initialState> = statistics.reducer
