import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { reducer as auth } from './auth'
import { reducer as user, UserState } from './user'
import { reducer as changeWorkspace } from './changeWorkspace'
import { reducer as eservices } from './eservices'
import { CompanyState, reducer as company } from './company'
import { reducer as expats } from './expats'
import { reducer as saudiCertificate } from './saudiCertificate'
import { reducer as correctOccupationRequest } from './correctOccupationRequest'
import { reducer as modalManager } from './modalManager'
import { reducer as invoices } from './invoices'
import { reducer as invoiceDetails } from './invoiceDetails'
import { reducer as transactions } from './transactions'
import { reducer as points } from './points'
import { reducer as buyPoints } from './buyPoints'
import { reducer as correctOccupation } from './correctOccupation'
import { reducer as paymentMethods } from './paymentMethods'
import { reducer as statistics } from './statistics'
import { reducer as utils } from './utils'
import { reducer as lookup } from './lookup'
import { reducer as wageProtection } from './wageProtection'
import { reducer as workerDataRequests } from './workerDataRequests'
import { reducer as workerDataSync } from './workerDataSync'
import { reducer as dormitoryLicense } from './dormitoryLicense'
import { reducer as dormitoryLicenseCreate } from './dormitoryLicenseCreate'
import { reducer as dormitoryLicenseDetails } from './dormitoryLicenseDetails'
import { reducer as googleMap } from './googleMap'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { combineReducers } from 'redux'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { RehydrateAction } from 'redux-persist/es/types'
import getEnvVar from '../utils/getEnvVar'

export const history = createBrowserHistory()

export const persistConfig = {
  key: 'root',
  storage,
  whitelist: [ 'company', 'user' ]
}

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  auth,
  user,
  changeWorkspace,
  eservices,
  company,
  expats,
  saudiCertificate,
  correctOccupationRequest,
  modalManager,
  invoices,
  invoiceDetails,
  transactions,
  points,
  buyPoints,
  correctOccupation,
  paymentMethods,
  statistics,
  utils,
  lookup,
  wageProtection,
  workerDataRequests,
  workerDataSync,
  dormitoryLicense,
  dormitoryLicenseCreate,
  dormitoryLicenseDetails,
  googleMap
})

export const rootReducer = createRootReducer(history)

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
    .concat(routerMiddleware(history)),
  devTools: getEnvVar('NODE_ENV') !== 'production'
})

// @ts-ignore
export const persistor = persistStore(store, { manualPersist: true })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type OmitedRehydrateAction = Omit<RehydrateAction, 'payload'> & {
  payload: {
    company: CompanyState
    user: UserState
  }
}
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
