import {
  createEntityAdapter,
  createSlice,
  Reducer,
  PayloadAction,
  createAsyncThunk
} from '@reduxjs/toolkit'
import { IFilter, TableSort } from '@takamol/unified-components'
import api from '../services/axios'
import { GET_INVOICES, REFRESH_INVOICE } from '../constants/api'
import { PaymentStatus } from '../components/invoices/InvoicesFilters'
import { ErrorType, FetchResponse, Meta, SerializedError } from '../@types'

export const initialFilters: IFilter = {
  'payments.serviceName': {
    value: null,
    action: 'eq',
  },
  'payments.status': {
    value: null,
    action: 'eq',
  },
  'payments.createdAt': {
    value: {
      from: null,
      to: null
    },
    action: ''
  },
  'payments.billingType': {
    value: null,
    action: 'eq',
  }
}

export type Invoice = {
  status: PaymentStatus
  pointAmount: number
  amount: string
  price: string
  tax: string
  productId: string
  entityId: number | null
  entityName: string
  redirectionUrl: string
  serviceName: string
  paymentUrl: string
  paymentId: string
  billingType: string
  createdAt: string
  purchasedAt: string | null
  id: string
  pdfFileId: number | null
}

type Invoices = {
  data: Invoice[]
  meta: {
    current_page: number
    page_count: number
    next_page: number
    prev_page: number
    page_size: number
  }
}

type InvoicesState = {
  invoices: Invoice[]
  filters: IFilter
  showFilters: boolean
  pageSize: number
  currentPage: number
  pageCount: number
  loading: boolean
  error: SerializedError
  sortModel: TableSort
}

const stateAdapter = createEntityAdapter()

export const initialState = stateAdapter.getInitialState<InvoicesState>({
  invoices: [],
  filters: initialFilters,
  showFilters: false,
  pageSize: 9,
  currentPage: 1,
  pageCount: 1,
  loading: true,
  error: {},
  sortModel: [ { id: 'payments.createdAt', desc: true } ]
})

type InvoicesQueryParams = {
  currentPage: number
  sort: string
  filters: string
}

export const fetchInvoices = createAsyncThunk<FetchResponse<Invoice[], Meta>, InvoicesQueryParams, ErrorType>(
  'invoices/fetchInvoices',
  async ({ currentPage, sort, filters }, { rejectWithValue }) => {
    return api('get', GET_INVOICES(currentPage, sort, filters), {}, rejectWithValue)
  }
)

export const refreshInvoice = createAsyncThunk<FetchResponse<string>, string, ErrorType>(
  'invoices/refreshInvoice',
  async (paymentId, { rejectWithValue }) => {
    return api('post', REFRESH_INVOICE(paymentId), {}, rejectWithValue)
  }
)

const invoices = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<IFilter>) {
      state.currentPage = 1
      state.filters = action.payload
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setShowFilters(state, action: PayloadAction<boolean>) {
      state.showFilters = action.payload
    },
    setSortModel(state, action: PayloadAction<TableSort>) {
      state.currentPage = 1
      state.sortModel = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInvoices.fulfilled, (state, action: PayloadAction<Invoices>) => {
        state.invoices = action.payload.data
        state.pageCount = action.payload?.meta?.page_count
        state.loading = false
        state.error = initialState.error
      })
      .addCase(fetchInvoices.rejected, (state, action: PayloadAction<any>) => {
        state.invoices = []
        state.loading = false
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
  }
})

export const reducer: Reducer<typeof initialState> = invoices.reducer
export const { setFilters, setCurrentPage, setLoading, setShowFilters, setSortModel } = invoices.actions
