import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Reducer
} from '@reduxjs/toolkit'
import api from '../services/axios'
import { DELETE_DORMITORY_LICENSE_REQUESTS, GET_DORMITORY_LICENSE_REQUESTS } from '../constants/api'
import { IFilter } from '@takamol/unified-components'
import { ErrorType, FetchResponse, Lookup, Meta, SerializedError } from '../@types'

const stateAdapter = createEntityAdapter()

export const initialFilters: IFilter = {
  'dormitoryLicenses.area': {
    value: '',
    action: 'like',
  },
  'licenseLocation.googleArea': {
    value: '',
    action: 'like',
  },
  'dormitoryLicenses.communityType': {
    value: null,
    action: 'eq',
  },
  'dormitoryLicenses.status': {
    value: null,
    action: 'eq',
  }
}

type LicenseLocation = {
  latitude?: number
  longitude?: number
  googleAreaEn?: string
  googleAreaAr?: string
  googleCityEn?: string
  googleCityAr?: string
  googleNeighborhoodEn?: string
  googleNeighborhoodAr?: string
}

export type DormitoryLicenseRequests = {
  id: string | number
  establishmentNumber: string
  status: string
  communityType: string
  supervisorName: string
  supervisorContactNumber: string
  area: string
  city: Lookup
  neighborhood: string
  areaCommunity: string
  capacity: number
  contractWithCompany: boolean
  contractCompanyName?: string
  contractFileId?: number
  contractFileName?: string
  licenseFleId: number
  licenseFileName: string
  licenseEndDate: string
  licenseStartDate: string
  declarationChecked: boolean
  createdAt: string | null
  updatedAt: string | null
  licenseLocation: LicenseLocation
}

type DormitoryLicenseState = {
  list: DormitoryLicenseRequests[]
  error: SerializedError
  pageCount: number
  currentPage: number
  pageSize: number
  loading: boolean
  filters: IFilter
  showFilters: boolean
  deleteLoading: boolean
}

export const initialState = stateAdapter.getInitialState<DormitoryLicenseState>({
  list: [],
  error: {},
  pageCount: 1,
  currentPage: 1,
  pageSize: 9,
  loading: true,
  filters: initialFilters,
  showFilters: false,
  deleteLoading: false
})

type FetchDormitoryLicenseRequestsArgs = { currentPage: number, sort: string, filters: string }

export const fetchDormitoryLicenseRequests = createAsyncThunk<FetchResponse<DormitoryLicenseRequests[], Meta>,
  FetchDormitoryLicenseRequestsArgs,
  ErrorType>(
    'dormitoryLicense/fetchDormitoryLicenseRequests',
    async ({
      currentPage,
      sort,
      filters
    }, { rejectWithValue }) => {
      return api('get', GET_DORMITORY_LICENSE_REQUESTS(currentPage, sort, filters), {}, rejectWithValue)
    }
  )

export const deleteDormitoryLicenseRequests = createAsyncThunk<void,
  string | number,
  ErrorType>(
    'dormitoryLicense/deleteDormitoryLicenseRequests',
    async (id, { rejectWithValue }) => {
      return api('delete', DELETE_DORMITORY_LICENSE_REQUESTS(id), {}, rejectWithValue)
    }
  )

const dormitoryLicense = createSlice({
  name: 'dormitoryLicense',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setFilters(state, action: PayloadAction<IFilter>) {
      state.currentPage = 1
      state.filters = action.payload
    },
    setShowFilters(state, action: PayloadAction<boolean>) {
      state.showFilters = action.payload
    },
    setDeleteLoading(state, action: PayloadAction<boolean>) {
      state.deleteLoading = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchDormitoryLicenseRequests.fulfilled, (state, action) => {
        state.list = action.payload.data
        state.error = initialState.error
        state.currentPage = action.payload.meta.current_page
        state.pageCount = action.payload.meta.page_count
        state.loading = false
      })
      .addCase(fetchDormitoryLicenseRequests.rejected, (state, action) => {
        state.loading = false
        state.error = {
          nameCode: 'modal.errorTitle',
          ...action.payload?.errors?.[0]
        }
      })
      .addCase(deleteDormitoryLicenseRequests.fulfilled, (state) => {
        state.deleteLoading = false
      })
      .addCase(deleteDormitoryLicenseRequests.rejected, (state) => {
        state.deleteLoading = false
      })
  }
});

export const reducer: Reducer<typeof initialState> = dormitoryLicense.reducer;
export const {
  setLoading,
  setCurrentPage,
  setFilters,
  setShowFilters,
  setDeleteLoading
} = dormitoryLicense.actions;
